import { InputLabel, styled } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

const FILE_LIMIT = 1024 * 1024 * 2;

const FileUploadContainer = styled('div')({
    border: "1px solid black",
    height: "300px",
    marginLeft: "18px",
    marginRight: "18px",
    marginTop: "28px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
});

const FileUploadLabel = styled(InputLabel)({
    marginTop: "24px",
    textAlign: "left",
    marginLeft: '18px',
});

const Img = styled('img')({
    height: "100%",
    width: "100%",
    objectFit: "cover",
});

const FileUpload = (props: {
    onChange?: (file: File) => void,
}) => {
    const { onChange } = props;
    const fileUpload = React.createRef<HTMLInputElement>();

    const [image, setImage] = useState<string>();
    const [drag, setDrag] = useState<boolean>(false);
    const [file, setFile] = useState<File>();
    const [fileError, setFileError] = useState<string>();

    const handleFileClick = () => {
        fileUpload.current?.click();
    };

    useEffect(() => {
        if (onChange && file) {
            onChange(file);
        }
    }, [file, onChange]);

    const handleFileUpload = async (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (event.currentTarget.files && event.currentTarget.files.length > 0) {
            const file: File = event.currentTarget.files[0];
            if (file.size > FILE_LIMIT) {
                setFileError(
                    "File limit has been exceeded. Please use a smaller image."
                );
            } else {
                setFileError(undefined);
                setImage(URL.createObjectURL(file));
                setFile(file);
            }
        }
    };

    const handleDragIn = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setDrag(true);
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleDragOut = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setDrag(false);
    };

    const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
            const file: File = event.dataTransfer.files[0];
            if (file.size > FILE_LIMIT) {
                setFileError(
                    "File limit has been exceeded. Please use a smaller image."
                );
            } else {
                setFileError(undefined);
                setImage(URL.createObjectURL(file));
                setFile(file);
            }
        }
    };

    return (
        <div>
            <FileUploadContainer
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onDragEnter={handleDragIn}
                onDragExit={handleDragOut}
                onClick={handleFileClick}
            >
                {!image && !drag && "Click to add image"}
                {!image && drag && "DROP HERE!"}
                {image && <Img alt="for-sale" src={image} />}
            </FileUploadContainer>
            <input
                style={{ display: "none" }}
                onChange={handleFileUpload}
                type="file"
                ref={fileUpload}
            />
            <FileUploadLabel
                error={fileError !== undefined}
                shrink
            >
                *Max size 2MB
                {fileError && <div>{fileError}</div>}
            </FileUploadLabel>
        </div>
    );
}


export default FileUpload;