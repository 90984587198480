import { useCallback, useEffect, useState } from "react";

export type AudioState = 'RECORDING' | 'PLAYING' | 'STOPPED';
const CHUNK_SIZE = 1000;
const WAV_MIME_TYPE = 'audio/webm';
const MP4_MIME_TYPE = 'audio/mp4';

const mimeType = MediaRecorder.isTypeSupported(WAV_MIME_TYPE) ? WAV_MIME_TYPE : MP4_MIME_TYPE;

export function useAudioUserMedia(audioElement: HTMLAudioElement, options: {
    timer: number,
}) {
    const [audio, setAudio] = useState<HTMLAudioElement>(audioElement);
    const [audioState, setAudioState] = useState<AudioState>('STOPPED');
    const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder>();
    const [timeleft, setTimeleft] = useState<number>(options.timer);

    const mediaRecordState = mediaRecorder?.state;

    useEffect(() => {
      const handlePause = () => {
          setAudioState('STOPPED');
      }

      audio.addEventListener('pause', handlePause);

      return () => {
          audio.removeEventListener('pause', handlePause);
      }
    }, [audio]);

    useEffect(() => {
        setAudio(audioElement);
    }, [audioElement]);

    const stopRecording = useCallback(() => {
        if (!mediaRecorder) {
            return;
        }

        mediaRecorder.stop();
        setMediaRecorder(undefined);
    }, [mediaRecorder]);

    useEffect(() => {
        if (mediaRecordState === 'recording' && timeleft <= 0) {
            stopRecording();
        }
    }, [audioState, timeleft, mediaRecordState, stopRecording]);

    useEffect(() => {
        if (audioState === 'RECORDING' && mediaRecorder) {
            if (mediaRecordState !== 'recording') {
                mediaRecorder.start(CHUNK_SIZE);
            }
        }
    }, [audioState, mediaRecorder, mediaRecordState]);


    function stopPlaying() {
        setAudioState('STOPPED');
        audio.pause();
    }

    function play() {
        if (!audio) {
            return;
        }

        setAudioState('PLAYING');
        audio.currentTime = 0;
        audio.play();
    }

    function record() {
        if (!mediaRecorder) {
            getReady();
        }
        setAudioState('RECORDING');
    }

    const getReady = useCallback(() => {
        audio.src = '';
        navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
            audio.srcObject = stream;

            const mediaRecorder = new MediaRecorder(stream, {
                mimeType: mimeType,
                bitsPerSecond: 128000,
            });

            const dataChunks: Blob[] = [];

            mediaRecorder.onstart = () => {
                setTimeleft(options.timer);
            }

            mediaRecorder.onstop = (event: Event) => {
                const blob = new Blob(dataChunks, { type: mimeType });
                audio.srcObject = null;
                audio.src = URL.createObjectURL(blob);

                stream.getTracks().forEach((t) => t.stop());
                setAudioState('STOPPED');
            }

            mediaRecorder.ondataavailable = (event: BlobEvent) => {
                const { data } = event;
                dataChunks.push(data);
                setTimeleft((currentTime) => currentTime -= 1);
            };

            mediaRecorder.onerror = (e: MediaRecorderErrorEvent) => {
                console.log(`MediaRecorder Error: ${e.error}`);
            };

            setMediaRecorder(mediaRecorder);
        }).catch((error) => {
            console.log(`There was an error: ${error}`);
        });

    }, [audio, options.timer]);

    return {
        getReady,
        stopPlaying,
        stopRecording,
        play,
        record,
        timeleft,
        audioState,
    }

}