export type ManifestForm = {
    name: string;
    orientation: string;
    trade: string;
    country: string;
    item: string;
    bibleVerse: string;    
}

export const orientations = ['STRAIGHT', 'HOMOSAPIEN'];

const bannedWords = [
    new RegExp(/n.*g.*r/, 'i'),
    new RegExp(/f.*a.*g/, 'i'),
];

export const checkForBannedWords = (word: string) => bannedWords.some((bannedWord) => word.match(bannedWord));