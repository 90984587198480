import { RefObject, useCallback, useEffect, useRef, useState } from "react";

export type VideoState = 'INITIAL' | 'GET_READY' | 'RECORDING' | 'PLAYING' | 'STOPPED' | 'REPLAY';
const CHUNK_SIZE = 1000;
const WEBM_MIME_TYPE = 'video/webm';
const MP4_MIME_TYPE = 'video/mp4';

const mimeType = MediaRecorder.isTypeSupported(WEBM_MIME_TYPE) ? WEBM_MIME_TYPE : MP4_MIME_TYPE;

export function useUserMedia(videoElement: RefObject<HTMLVideoElement>, options: {
    timer: number,
}) {
    const [video, setVideo] = useState<HTMLVideoElement | null>(videoElement.current);
    const [supportsFacing, setSupportsFacing] = useState<boolean>(false);
    const isFacingFront = useRef<boolean>(true);
    const [videoState, setVideoState] = useState<VideoState>('INITIAL');
    const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder>();    
    const [timeleft, setTimeleft] = useState<number>(0);

    useEffect(() => {
        const supports = navigator.mediaDevices.getSupportedConstraints();
        if (supports['facingMode']) {
            setSupportsFacing(true);
        }
    }, []);

    useEffect(() => {
        setVideo(videoElement.current);
    }, [videoElement]);

    const stopRecording = useCallback(() => {
        if (!mediaRecorder) {
            return;
        }

        if (!video) {
            return;
        }

        mediaRecorder.stop();
    }, [mediaRecorder, video]);

    function stopPlaying() {
       if (!video) {
           return;
       }
       video.pause();
    }

    function rewind() {
        if (!video) {
            return;
        }

        video.currentTime = 0;
    }

    function play() {
        if (!video) {
            return;
        }

        video.play();
    }

    function record() {
        if (!video) {
            return;
        }

        if (!mediaRecorder) {
            return;
        }
 
        setVideoState('RECORDING');
        video.muted = true;
        if (mediaRecorder.state !== 'recording') {
            mediaRecorder.start(CHUNK_SIZE);
        }
    }

    function flip() {
        isFacingFront.current = !isFacingFront.current ;
        getReady();
    }

    function getReady() {        
        if (!!video) {      
            if (mediaRecorder && mediaRecorder.stream) {
                mediaRecorder.stream.getTracks().forEach((track) => track.stop());
            }
            
            video.src = '';
            video.muted = true;
            

            setVideoState('GET_READY');
            navigator.mediaDevices.getUserMedia({ video: { 
                facingMode: isFacingFront.current ? 'user' : 'environment',
            }, audio: true }).then((stream) => {
                video.srcObject = stream;                                

                const mediaRecorder = new MediaRecorder(stream, {
                    mimeType: mimeType,
                    bitsPerSecond: 5000000,
                });

                const dataChunks: Blob[] = [];

                mediaRecorder.onstart = () => {                                        
                    setTimeleft(options.timer);
                }

                mediaRecorder.onstop = (event: Event) => {
                    const blob = new Blob(dataChunks, { type: mimeType });
                    video.srcObject = null;
                    video.src = URL.createObjectURL(blob);
                    
                    stream.getTracks().forEach((t) => t.stop());
                    video.muted = false;
                    setVideoState('PLAYING');                                
                }
                
                mediaRecorder.ondataavailable = (event: BlobEvent) => {
                    const { data } = event;
                    dataChunks.push(data);                    
                    setTimeleft((currentTime) => currentTime -= 1);                    
                };

                setMediaRecorder(mediaRecorder);
                video.play();
                setTimeout(() => {
                    mediaRecorder.stop();
                }, options.timer * 1000);
            }).catch((error) => {
                console.log(`There was an error: ${error}`);
            });
        }

    }

    return {
        getReady,
        stopPlaying,
        stopRecording,
        play,
        record,
        rewind,
        timeleft,
        videoState,
        supportsFacing,
        flip,
        isFacingFront,
    }

}