import { makeStyles } from "@material-ui/core";
import React from "react";

interface Props {
  enabled: boolean;
}

const Battery = (props: Props) => {
  const { enabled } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {enabled ? (
        <div className={classes.batteryContainer}>
          <div className={classes.terminal}>
            <div className={classes.negativeTerminal} />
            <div className={classes.positiveTerminal} />
          </div>
          <div className={classes.battery}>
            <div className={classes.terminal}>
              <div className={classes.batteryTerminal} />
              <div className={classes.batteryTerminal} />
            </div>
            <div className={classes.batteryTop} />
            <div className={classes.batteryBottom}>
              <p className={classes.voltageText}>+100V</p>
            </div>
          </div>
        </div>
      ) : (
        <div className={classes.emptyContainer}>
          <div className={classes.terminal}>
            <div className={classes.negativeTerminal} />
            <div className={classes.positiveTerminal} />
          </div>
          <p className={classes.addText}>ADD</p>
        </div>
      )}
    </div>
  );
};

const useStyles = makeStyles({
  root: {
    width: "100%",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    aspectRatio: "2 / 4",
    filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
    background: "radial-gradient(179.43% 179.43% at 50% 50%, #FAFAFF 0%, #000000 100%)",
  },
  batteryContainer: {
    width: "90%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  battery: {
    width: "100%",
    aspectRatio: "2 / 4",
    display: "flex",
    flexDirection: "column",
    filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
  },
  batteryTop: {
    height: "30%",
    background: "radial-gradient(86.27% 86.27% at 50% 50%, #EEB67A 0%, #BB6931 100%)",
    border: "1px solid #000000",
    borderRadius: "4px 4px 0px 0px",
  },
  batteryBottom: {
    backgroundColor: "#000000",
    borderRadius: "0px 0px 4px 4px",
    height: "60%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  emptyContainer: {
    width: "90%",
    height: "100%",
  },
  terminal: {
    display: "flex",
    justifyContent: "space-around",
  },
  positiveTerminal: {
    width: "9px",
    height: "9px",
    backgroundColor: "#BB0000",
  },
  negativeTerminal: {
    width: "9px",
    height: "9px",
    backgroundColor: "black",
  },
  batteryTerminal: {
    width: "24px",
    height: "9px",
    backgroundColor: "#A0A0A0",
    border: "1px solid #000000",
  },
  addText: {
    color: "black",
    textAlign: "center",
    marginTop: "100%",
    fontFamily: "VT323",
  },
  voltageText: {
    color: "#BB0000",
    textAlign: "center",
    fontFamily: "VT323",
    fontSize: "24px",
    margin: 0,
  },
});

export default Battery;
