import { CircularProgress, InputLabel, makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import qs from "query-string";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Controller, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import AgreementModal from "./AgreementModal";
import { DonationRequest, DonationType, useCreateDonation } from "./hooks/useCreateDonation";
import Leaderboard from "./leaderBoard";
import { uploadMedia, validatePromocode } from "./services";
import { Link } from "react-router-dom";

const FILE_LIMIT = 1024 * 1024 * 2;

interface DonationForm {
  name: string;
  amount: number;
  message: string;
  mediaId?: number;
  promocode?: string;
}

const HomeComponent = () => {
  const fileUpload = React.createRef<HTMLInputElement>();
  const location = useLocation();

  const [openAgreement, setOpenAgreement] = useState<boolean>(false);
  const queryString = qs.parse(location.search);
  const isMailback = queryString?.isMailback === "1" || false;
  const code = (queryString?.code as string) || undefined;
  const dollarValues = isMailback ? [19.99, 29.99, 1999.99] : [10, 20, 100];
  const {
    control,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<DonationForm>({
    mode: "onChange",
    defaultValues: {
      amount: dollarValues[0],
    },
  });
  const [isPromoOpen, setIsPromoOpen] = useState<boolean>(false);
  const [isValidPromo, setIsValidPromo] = useState<boolean>(false);

  const donationMutation = useCreateDonation({
    onSuccess: (response) => {
      if (response.success) {
        window.location.href = response.redirectUrl;
      }
    },
  });

  const onSubmit = handleSubmit(async (form) => {
    const { name, amount, message, promocode } = form;
    const donation: DonationRequest = {
      name,
      amount,
      message,
      isMailback,
      code,
      type: DonationType.IMAGE,
      promocode,
    };

    setIsSubmitting(true);

    if (file) {
      const response = await uploadMedia(file, file.name);
      donation.mediaId = response.id;
    }
    donationMutation.mutate(donation);
  });

  const classes = useStyles();
  const [image, setImage] = useState<string>();
  const [drag, setDrag] = useState<boolean>(false);
  const [file, setFile] = useState<File>();
  const [fileError, setFileError] = useState<string>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleSetAmount = (amount: number) => () => {
    setValue("amount", amount);
  };

  const handleFileClick = () => {
    fileUpload.current?.click();
  };

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.files && event.currentTarget.files.length > 0) {
      const file: File = event.currentTarget.files[0];
      if (file.size > FILE_LIMIT) {
        setFileError("File limit has been exceeded. Please use a smaller image.");
      } else {
        setFileError(undefined);
        setImage(URL.createObjectURL(file));
        setFile(file);
      }
    }
  };

  const handleDragIn = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setDrag(true);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDragOut = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setDrag(false);
  };

  const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      const file: File = event.dataTransfer.files[0];
      if (file.size > FILE_LIMIT) {
        setFileError("File limit has been exceeded. Please use a smaller image.");
      } else {
        setFileError(undefined);
        setImage(URL.createObjectURL(file));
        setFile(file);
      }
    }
  };

  const handleOpenAgreement = async () => {
    const isValid = true;
    if (isValid) {
      setOpenAgreement(true);
    }
  };

  const handleCloseAgreement = () => {
    setOpenAgreement(false);
  };

  const handleOpenPromo = () => {
    setIsPromoOpen(!isPromoOpen);
    console.log(isPromoOpen);
  };

  const handleValidatePromoCode = async () => {
    const { promocode } = getValues();
    if (promocode) {
      const isValid = await validatePromocode(promocode);
      if (isValid) {
        setIsValidPromo(true);
      }
    }
  };

  return (
    <div className={classes.root}>
      <Helmet>
        <style type="text/css">{"body { background-color: #1b1537; }"}</style>
      </Helmet>
      <div className={classes.container}>
        <form onSubmit={onSubmit} noValidate>
          <AgreementModal
            open={openAgreement}
            handleClose={handleCloseAgreement}
            isSubmitting={isSubmitting}
            onSubmit={onSubmit}
          />

          <div className={classes.donateContainer}>
            <div className={classes.donateHeader}>
              <div className={classes.donateHeaderImage}>
                <div className={classes.donateHeaderImageOverlay}>
                  <Link to={"/"}>
                    <div className={classes.donateHeaderPortrait} />
                  </Link>
                  <div className={classes.donateHeaderTextContainer}>
                    <div className={classes.donateHeaderText}>Ark Printed Donations</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.donateMoneyContainer}>
              <div className={classes.donateMoneyContainerInside}>
                <div className={classes.donateMoneyContainerInputContainer}>
                  <div className={classes.donateMoneyContainerInputNameContainer}>
                    <Controller
                      control={control}
                      name="name"
                      rules={{
                        maxLength: 20,
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          error={errors?.name !== undefined}
                          fullWidth
                          helperText={
                            (errors?.name?.type === "maxLength" && "Max amount of characters is 20!") ||
                            "Leave blank to be Anonymous"
                          }
                          id="standard-full-width"
                          label="Your Name"
                          name="name"
                          variant="outlined"
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
              {isValidPromo ? (
                <div className={classes.promoGiftContainer}>
                  <h2 style={{ margin: 0 }}>FREE - A gift from Richard Burnish</h2>
                </div>
              ) : (
                <div className={classes.donateMoneyPresetsContainer}>
                  <div className={classes.donateMoneyPresetContainerInterior}>
                    <InputLabel shrink>Donation Amount</InputLabel>
                    <ButtonGroup size="large" color="primary" aria-label="large outlined primary button group">
                      {dollarValues.map((v) => (
                        <Button onClick={handleSetAmount(v)}>${Number(v).toFixed(2)}</Button>
                      ))}
                    </ButtonGroup>
                  </div>
                  <FormControl error={errors?.amount !== undefined} className={classes.dollarAmmountInput}>
                    <InputLabel htmlFor="standard-adornment-amount">Amount</InputLabel>

                    <Controller
                      control={control}
                      name="amount"
                      rules={{
                        required: true,
                        min: dollarValues[0],
                      }}
                      render={({ field }) => (
                        <Input
                          {...field}
                          name="amount"
                          type="number"
                          startAdornment={<InputAdornment position="start">$</InputAdornment>}
                        />
                      )}
                    />
                    {errors?.amount !== undefined && <p className="MuiFormHelperText-root">Thou shalt not be cheap!</p>}
                  </FormControl>
                </div>
              )}
              {!isValidPromo ? (
                <div className={classes.promoCodeContainer}>
                  {isPromoOpen ? (
                    <div className={classes.promoTextInputContainer}>
                      <Controller
                        control={control}
                        name="promocode"
                        rules={{
                          maxLength: 20,
                        }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            error={errors?.name !== undefined}
                            fullWidth
                            label="Promo Code"
                            variant="outlined"
                          />
                        )}
                      />

                      <Button size="medium" color="primary" onClick={handleValidatePromoCode}>
                        Apply
                      </Button>
                    </div>
                  ) : (
                    <Button size="small" color="primary" onClick={() => handleOpenPromo()}>
                      + Promo code
                    </Button>
                  )}
                </div>
              ) : null}
              <div className={classes.tipMessageContainer}>
                <Controller
                  control={control}
                  name="message"
                  rules={{
                    required: true,
                    maxLength: 255,
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={errors?.message !== undefined}
                      helperText={
                        (errors?.message?.type === "required" && "A message is required!") ||
                        (errors?.message?.type === "maxLength" && "Max amount of characters is 255!") ||
                        "255 Characters"
                      }
                      fullWidth
                      label="Donation Message"
                      multiline
                      rows={6}
                      variant="outlined"
                    />
                  )}
                />
              </div>
              <div>
                <div
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                  onDragEnter={handleDragIn}
                  onDragExit={handleDragOut}
                  onClick={handleFileClick}
                  className={classes.fileUploadContainer}
                >
                  {!image && !drag && "Drag and drop an image"}
                  {!image && drag && "DROP HERE!"}
                  {image && <img className={classes.img} alt="upload" src={image} />}
                </div>
                <input style={{ display: "none" }} onChange={handleFileUpload} type="file" ref={fileUpload} />
                <InputLabel error={fileError !== undefined} shrink className={classes.fileUploadLabel}>
                  *Max size 2MB
                  {fileError && <div>{fileError}</div>}
                </InputLabel>
              </div>
              <div className={classes.donateButtonContainer}>
                <Button
                  disabled={isSubmitting}
                  type="button"
                  onClick={handleOpenAgreement}
                  className={classes.donateButton}
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  {isSubmitting ? (
                    <CircularProgress thickness={5.0} color={"inherit"} size={30} />
                  ) : (
                    "Donate to Richard"
                  )}
                </Button>
                <InputLabel className={classes.fileUploadLabel}>
                  * All donations must comply with the{" "}
                  <a href="https://www.youtube.com/about/policies/#community-guidelines">Community-Guidelines</a>.
                  Making a donation in no way guarantees that your submission will be shown on the program. All
                  donations are subject to Richard's discretion and God's discretion, as well. Amen.
                </InputLabel>
                <div className={classes.leaderboardContainer}>
                  <Leaderboard />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  root: {
    fontFamily: "Nunito",
    fontWeight: 400,
    maxWidth: "640px",
    margin: "0 auto",
  },
  promoCodeContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    padding: "18px",
    marginTop: "24px",
  },
  promoGiftContainer: {
    marginTop: "24px",
    textAlign: "center",
    padding: "24px",
    color: "white",
    backgroundColor: "#1b1537",
  },
  promoTextInputContainer: {
    display: "flex",
    widht: "100%",
    flexDirection: "row",
  },
  container: {
    marginTop: "28px",
    display: "flex",
    maxHeight: "6000px",
    flexDirection: "column",
  },
  donateContainer: {
    display: "flex",
    flexDirection: "column",
    flex: "0 0 auto",
    boxSizing: "inherit",
  },
  donateHeader: {
    maxWidth: "640px",
    zIndex: 200,
    position: "relative",
  },
  donateHeaderImage: {
    maxWidth: "640px",
    position: "relative",
    backgroundImage: "url(https://cdn.streamelements.com/static/user/tipping_default.jpg)",
    height: "200px",
    backgroundPosition: "center center",
  },
  donateHeaderImageOverlay: {
    paddingLeft: "30px",
    paddingRight: "30px",
    marginBottom: "-20px",
    flexDirection: "row",
    alignItems: "center",
    position: "absolute",
    bottom: "0px",
    display: "flex",
    WebkitBoxAlign: "center",
  },
  donateHeaderPortrait: {
    backgroundImage:
      "url(https://yt3.ggpht.com/a/AATXAJySVnNsce8MOTeldpGFihXyPnZbK-IHjUaHGA=s88-c-k-c0xffffffff-no-rj-mo)",
    backgroundSize: "cover",
    height: "110px",
    zIndex: 200,
    width: "110px",
    cursor: "pointer",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    borderRadius: "50%",
    borderWidth: "4px",
    borderStyle: "solid",
    borderColor: "white",
    borderImage: "initial",
    overflow: "hidden",
    flex: "0 0 auto",
    boxSizing: "border-box",
    display: "block",
  },
  donateHeaderTextContainer: {
    marginLeft: "30px",
    alignItems: "flex-start",
    display: "flex",
    WebkitBoxPack: "center",
    justifyContent: "center",
    flexDirection: "column",
    WebkitBoxAlign: "center",
    boxSizing: "border-box",
  },
  donateHeaderText: {
    color: "white",
    textShadow: "rgba(0, 0, 0, 0.5) 0px 2px 4px",
    textAlign: "left",
    fontSize: "20px",
    letterSpacing: "1.5px",
    overflowWrap: "break-word",
    wordBreak: "break-word",
    paddingLeft: "18px",
    paddingRight: "18px",
    boxSizing: "border-box",
    display: "block",
  },
  donateMoneyContainer: {
    paddingTop: "20px",
    backgroundColor: "white",
    width: "100%",
    maxWidth: "640px",
    position: "relative",
    flex: "1 1 0%",
    overflow: "hidden",
    boxSizing: "border-box",
    display: "block",
  },
  donateMoneyContainerInside: {
    opacity: 1,
    backgroundColor: "white",
    paddingLeft: "18px",
    paddingRight: "18px",
    display: "flex",
    flexDirection: "column",
    margin: "0px !important",
    boxSizing: "border-box",
  },
  donateMoneyContainerInputContainer: {
    boxSizing: "border-box",
    display: "block",
  },
  donateMoneyContainerInputNameContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    WebkitBoxPack: "justify",
    boxSizing: "border-box",
    width: "100%",
  },
  donateMoneyPresetsContainer: {
    display: "grid",
    gridTemplateColumns: "5fr 1fr",
    gridGap: "24px",
    marginTop: "28px",
    width: "100%",
  },
  donateMoneyPresetContainerInterior: {
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    marginLeft: "18px",
  },
  dollarAmmountInput: {
    paddingRight: "18px",
  },
  tipMessageContainer: {
    display: "flex",
    paddingLeft: "18px",
    paddingRight: "18px",
    marginTop: "28px",
  },
  fileUploadContainer: {
    border: "1px solid black",
    height: "300px",
    marginLeft: "18px",
    marginRight: "18px",
    marginTop: "28px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  fileUploadLabel: {
    marginTop: "24px",
    textAlign: "center",
    lineHeight: "24px",
  },
  donateButtonContainer: {
    marginTop: "32px",
    paddingLeft: "18px",
    paddingRight: "18px",
    paddingBottom: "32px",
  },
  donateButton: {
    width: "100%",
    background: "linear-gradient(284deg, rgb(112, 87, 220), rgb(87, 112, 220))!important",
  },
  leaderboardContainer: {
    width: "100%",
    margin: "0 auto",
    marginTop: "32px",
  },
  img: {
    height: "100%",
    width: "100%",
    objectFit: "cover",
  },
  error: {
    color: "#FF0000",
    "& > input,select": {
      color: "#FF0000",
      borderColor: "#FF0000",
    },
    "& p": {
      margin: "8px 0px",
      padding: 0,
    },
  },
});

export default HomeComponent;
