import { makeStyles } from '@material-ui/core';
import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { countries } from '../../Utils/countries';
import { ArkContext, arkContext } from '../Contexts/ArkContext';
import { useManifest } from '../Contexts/ManifestContext';
import { checkForBannedWords, ManifestForm, orientations } from './form';

const ArkManifestComponent = () => {

    const classes = useStyles();
    const navigate = useNavigate();
    const { ark } = useContext<ArkContext>(arkContext);
    const { setManifest } = useManifest();

    if (ark.selected === undefined) {
        navigate('/ark');
    }

    const { formState: { errors }, register, handleSubmit } = useForm<ManifestForm>({
        mode: 'onChange',
        defaultValues: {
            orientation: 'STRAIGHT',
            country: 'US',
        },
    }
    );
    const onSubmit = handleSubmit(({ name, trade, country, item, bibleVerse }) => {
        setManifest({ name, trade, country, item, bibleVerse, seat: ark.selected });
        navigate('/ark/review');
    });

    const { selected } = ark;

    window.scrollTo(0, 0)

    return <div className={classes.root}>
        <Helmet>
            <style type="text/css">
                {'body { background-color: #EBD093; }'}
            </style>
        </Helmet>

        <h1 className={classes.title} >Ark Manifest</h1>
        <div className={classes.subtitle} >
            <p>Please enter your information</p>
        </div>

        <form onSubmit={onSubmit} >

            <div className={classes.formContainer} >
                <h2 className={classes.title}>Seat {`${selected?.row}${selected?.column}`}</h2>

                <div className={`${classes.formSection} ${errors?.name ? classes.error : ''}`}>
                    <label htmlFor="name" >Name</label>
                    <input
                        {...register(
                            "name",
                            {
                                required: true,
                                maxLength: 30,
                                validate: (value) => !checkForBannedWords(value)
                            }
                        )}
                    />
                    <p>{errors?.name?.type === 'required' ? 'Name is a required field.' : ''}</p>
                    <p>{errors?.name?.type === 'maxLength' ? 'Max length is 30 characters.' : ''}</p>
                    <p>{errors?.name?.type === 'validate' ? 'Bad word! Repent and say 10 Hail Marys!' : ''}</p>
                </div>
                <div className={`${classes.formSection} ${errors?.trade ? classes.error : ''}`}>
                    <label htmlFor="trade">Trade/Profession</label>
                    <input
                        {...register(
                            "trade",
                            {
                                required: true,
                                maxLength: 30,
                                validate: (value) => !checkForBannedWords(value)
                            }
                        )}
                    />
                    <p>{errors?.trade?.type === 'required' ? 'Trade is a required field.' : ''}</p>
                    <p>{errors?.trade?.type === 'maxLength' ? 'Max length is 30 characters.' : ''}</p>
                    <p>{errors?.trade?.type === 'validate' ? 'Bad word! Repent and say 10 Hail Marys!' : ''}</p>
                </div>
                <div className={`${classes.formSection} ${errors?.item ? classes.error : ''}`}>
                    <label htmlFor="item" >Your Item</label>
                    <input
                        {...register(
                            "item",
                            {
                                required: true,
                                maxLength: 30,
                                validate: (value) => !checkForBannedWords(value)
                            }
                        )}
                    />
                    <p className={classes.help} >You may bring one item with you on the Ark.</p>
                    <p>{errors?.item?.type === 'required' ? 'Item is a required field.' : ''}</p>
                    <p>{errors?.item?.type === 'maxLength' ? 'Max length is 30 characters.' : ''}</p>
                    <p>{errors?.item?.type === 'validate' ? 'Bad word! Repent and say 10 Hail Marys!' : ''}</p>
                </div>
                <div className={`${classes.formSection} ${errors?.bibleVerse ? classes.error : ''}`}>
                    <label htmlFor="item" >Your Favourite Bible Verse</label>
                    <input
                        {...register(
                            "bibleVerse",
                            {
                                required: true,
                                maxLength: 300,
                                validate: (value) => !checkForBannedWords(value)
                            }
                        )}
                    />
                    <p>{errors?.bibleVerse?.type === 'required' ? 'Bible Verse is a required field.' : ''}</p>
                    <p>{errors?.bibleVerse?.type === 'maxLength' ? 'Max length is 300 characters.' : ''}</p>
                    <p>{errors?.bibleVerse?.type === 'validate' ? 'Bad word! Repent and say 10 Hail Marys!' : ''}</p>
                </div>
                <div className={`${classes.formSection} ${errors?.country ? classes.error : ''}`}>
                    <label htmlFor="country" >Country</label>
                    <select
                        {...register(
                            "country",
                            {
                                required: true,
                                maxLength: 300,
                                validate: (value) => !checkForBannedWords(value)
                            }
                        )}
                    >
                        {countries.map((c) => (
                            <option key={c.code} value={c.code} >{c.name}</option>
                        ))}
                    </select>
                </div>
                <div className={`${classes.formSection} ${errors?.orientation ? classes.error : ''}`}>
                    <label htmlFor="orientation" >Sexual Orientation</label>
                    <select
                        {...register(
                            "orientation",
                            {
                                pattern: /STRAIGHT/
                            }
                        )}

                    >
                        {orientations.map((o) => (
                            <option key={o} value={o} >{o}</option>
                        ))}
                    </select>
                </div>
            </div>
            {!errors.orientation && <button className={classes.continueButton} type="submit">CONTINUE</button>}
            {errors.orientation && <button className={classes.inactiveButton} type="button" disabled >NO SEATS AVAILABLE</button>}
            <p style={{ fontWeight: 700, color: 'red', textAlign: 'center' }}>This info will be visible to the public on the Ark Manifest.</p>

        </form>
    </div>
};

const useStyles = makeStyles({
    root: {
        backgroundColor: '#EBD093',
        padding: '0px 16px',
        fontFamily: 'Nunito',
        fontWeight: 700,
        maxWidth: '500px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        margin: '0 auto',
    },
    continueButton: {
        backgroundColor: '#20559A',
        color: '#FFFFFF',
        width: '100%',
        height: '55px',
        maxWidth: '500px',
        marginBottom: '32px',
        fontSize: '16px',
        fontWeight: 700,
        cursor: 'pointer',
    },
    inactiveButton: {
        backgroundColor: 'Grey',
        color: '#FFFFFF',
        width: '100%',
        height: '55px',
        maxWidth: '500px',
        marginBottom: '32px',
        fontSize: '16px',
        fontWeight: 700,
    },
    'form': {
        boxSizing: 'border-box',
    },
    formSection: {
        margin: '0 0 16px 0',
        boxSizing: 'border-box',
        '& > label': {
            textAlign: 'left',
            display: 'block',
            fontSize: '16px',
            lineHeight: '20px',
            marginBottom: '8px',
        },
        '& > input,select': {
            boxSizing: 'border-box',
            width: '100%',
            border: 'solid 1px #C4C9CC',
            padding: '12px 0px 12px 14px',
            fontSize: '16px',
            height: "55px",
        },
    },
    error: {
        color: '#FF0000',
        '& > input,select': {
            color: '#FF0000',
            borderColor: '#FF0000',
        },
        '& p': {
            margin: '8px 0px',
            padding: 0,
        }
    },
    help: {
        margin: 0,
        padding: 0,
        fontSize: '12px',
        lineHeight: '16px',
        color: '#6D787F',
        verticalAlign: 'middle',
        display: 'inline-block',
    },
    title: {
        textAlign: 'center',
        fontWeight: 400,
        fontSize: '24px',
    },
    subtitle: {
        marginBottom: '16px',
        textAlign: 'center',
        '& p': {
            fontSize: '16px',
            lineHeight: '22px',
            margin: 0,
            padding: 0,
        }
    },
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#FEE9BB',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        boxSizing: 'border-box',
        marginBottom: '32px',
        padding: '16px 16px 16px 16px',
    },
    'body': {
        margin: 0,
        padding: 0,
        backgroundColor: '#EBD093',
    }
});

export default ArkManifestComponent;