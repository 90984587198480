import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Seat } from '../Services/service';

interface IProps {
    show: boolean;
    selected?: Seat;
}

const useStyles = makeStyles({
    root: {
        zIndex: 2,
        position: 'fixed',
        bottom: 0,
        backgroundColor: '#FEE9BB',
        color: '#000000',
        display: 'flex',        
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '16px',
        boxSizing: 'border-box',
        boxShadow: '0px 0px 24px rgba(0, 0, 0, 0.5)',
    },
    info: {
        fontSize: '16px',
        textAlign: 'left',
        fontFamily: 'Nunito',
        fontWeight: 700,
        '& p': {
            padding: 0,
            margin: 0,
        },
    },
    confirmButton: {
        backgroundColor: '#20559A',
        border: 0,
        color: '#FFFFFF',
        padding: '16px 24px',
        height: '55px',
        fontSize: '16px',
        fontWeight: 700,
        cursor: 'pointer',
    },
});

const ConfirmOverlay = (props: IProps) => {

    const classes = useStyles();
    const { selected, show } = props;

    const selectedSeats = `${selected?.row}${selected?.column}`;
    const totalPrice = selected?.price;

    const setClass = () => {
        switch (selected?.section) {
            case 1:
                return "First Class";
            case 2:
                return "Second Class";
            case 3:
                return "Third Class";
            default:
                return "It's a miracle!";
        }
    }

    const seatClass =  setClass();

    if (!show) {
        return <></>
    }

    return <div className={classes.root}>
            <div className={classes.info} >
                <p>{seatClass}</p>
                <p>Seat: {selectedSeats} </p>
                <p>${totalPrice}</p>

            </div>
            <div>
                <Link to="/ark/manifest">
                    <button className={classes.confirmButton}>CONTINUE</button>
                </Link>                
            </div>
        </div>
    
}

export default ConfirmOverlay;