import { CircularProgress, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { ArkContext, arkContext } from "../Contexts/ArkContext";
import {
  getArk, getPassengers, Passenger, SeatType, Section
} from "../Services/service";
import ConfirmOverlay from "./PassengerOverlay";

const Passengers = () => {
  const classes = useStyles();
  const { ark, setArk } = useContext<ArkContext>(arkContext);
  const [showOverlay, setShowOverlay] = useState<boolean>(true);
  const [passengers, setPassengers] = useState<Passenger[]>([]);
  const [selectedPassenger, setSelectedPassenger] = useState<Passenger>();

  const { sections } = ark;

  const sectionLength = ark.sections.length;

  useEffect(() => {
    if (sectionLength === 0) {
      getArk().then((data) => {
        setArk(data);
      });
    }
  }, [sectionLength, setArk]);

  useEffect(() => {
    getPassengers().then((data) => {
      setPassengers(data);
    });
  }, []);

  const handleSeatClick = (
    section: number,
    row: number,
    column: string
  ) => async () => {
    const passenger = passengers.find(
      (p) => p.section === section && p.row === row && p.column === column
    );
    if (passenger) {
      setSelectedPassenger(passenger);
      setShowOverlay(true);
    }
  };

  const getSeatType = (
    section: number,
    row: number,
    column: string
  ): SeatType => {
    var type: SeatType = SeatType.BOOKED;
    const isSelected =
      selectedPassenger?.section === section &&
      selectedPassenger.row === row &&
      selectedPassenger.column === column;
    const isReserved = passengers.some(
      (s) => s.section === section && s.row === row && s.column === column
    );

    if (isSelected) {
      type = SeatType.SELECTED;
    } else if (isReserved) {
      type = SeatType.BLANK;
    }
    return type;
  };

  const getSeatSVG = (section: number, row: number, column: string) => {
    const seatType = getSeatType(section, row, column);

    switch (section) {
      case 1:
        return `images/First${seatType.toString()}.svg`;
      case 2:
        return `images/Second${seatType.toString()}.svg`;
      case 3:
        return `images/Third${seatType.toString()}.svg`;
    }
    return "";
  };

  const tableCell = (section: number, row: number, column: string) => {
    return (
      <td
        onClick={handleSeatClick(section, row, column)}
        key={`${section}-${row}-${column}`}
      >
        <img
          style={{ cursor: "pointer" }}
          src={getSeatSVG(section, row, column)}
          alt=""
        />
      </td>
    );
  };

  if (sections.length === 0) {
    return (
      <div className={classes.root}>
        <CircularProgress />
      </div>
    );
  }
  console.log(showOverlay);
  return (
    <div className={classes.root}>
      <Helmet>
        <style type="text/css">{"body { background-color: #EBD093; }"}</style>
      </Helmet>
      <h1 className={classes.title}>THE ARK</h1>
      <table style={{ marginBottom: "200px" }}>
        <thead className={classes.header}>
          <tr>
            <th />
            {sections[0].columns.map((c) => (
              <th key={c} className={classes.yAxis}>
                {c}
              </th>
            ))}
          </tr>
        </thead>

        {sections.map((s: Section) => (
          <tbody key={s.id} className={classes.section}>
            {s.rows.map((r: number) => (
              <tr key={`${s.id}-${r}`}>
                <td className={classes.xAxis}>{r}</td>
                {s.columns.map((c: string) => tableCell(s.id, r, c))}
              </tr>
            ))}
            <tr>
              <td className={classes.spacer} colSpan={s.columns.length} />
            </tr>
          </tbody>
        ))}
      </table>
      <ConfirmOverlay show={true} selected={selectedPassenger} />
    </div>
  );
};

const useStyles = makeStyles({
  root: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "300px",
    fontFamily: "Nunito",
    fontWeight: 300,
  },
  title: {
    fontSize: "24px",
    lineHeight: "33px",
    fontWeight: 400,
  },
  header: {},
  section: {
    marginBottom: "48px",
  },
  icon: {
    height: "42px",
    width: "42px",
    padding: "4px",
  },
  xAxis: {
    fontSize: "16px",
    paddingRight: "4px",
    fontWeight: 700,
  },
  yAxis: {
    fontSize: "16px",
    paddingBottom: "4px",
    fontWeight: 700,
  },
  spacer: {
    height: "48px",
  },
});

export default Passengers;
