import { Link, makeStyles } from '@material-ui/core';
import React from 'react';
import { Helmet } from 'react-helmet';
import Background from '../../Assets/line.jpg';

const HomeComponent = () => {

    const classes = useStyles();    

    return <div className={classes.root}>
        <Helmet>
            <style type="text/css">
                {'body { background-color: #EBD093; }'}
            </style>
        </Helmet>
        <h1 style={{ textAlign: 'center' }}>Reserve Your Place In Heaven</h1>
        <h2 className={classes.title}>Book your seat on The Ark before construction begins and change your life forever.</h2>
        <h4>First Class Seats - 19.99</h4>
        <p>First class ticket holders do not have to row the Ark. They are seated near the front of the Ark. First class ticket holders will also receive first class perks.</p>
        <h4>Second Class Seats - 9.99</h4>
        <p>Second class ticket holders will have to row the Ark on an alternating schedule. They are seated in the center of the Ark.</p>
        <h4>Third Class Seats - 4.99</h4>
        <p>Third class ticket holders will have to row the ENTIRE TIME aboard The Ark. They are seated in the rear of the Ark.</p>
        <Link href="/ark/book">
            <button className={classes.continueButton} >CONTINUE</button>
        </Link>
    </div>
};

const useStyles = makeStyles({
    root: {
        fontFamily: 'Nunito',
        fontWeight: 400,
        backgroundColor: '#EBD093',
        maxWidth: '500px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        margin: '0 auto',
        backgroundImage: `url(${Background})`,
        backgroundSize: 'cover',

        '& h4': {
            margin: '0px 0px 8px 0px',
            padding: 0,
            fontSize: '16px',
            lineHeight: '22px',
            textAlign: 'center',
            fontWeight: 700,
        },
        '& p': {
            margin: '0px 0px 24px 0px',
            padding: 0,
            fontSize: '16px',
            lineHeight: '22px',
            textAlign: 'center',
        },
    },
    title: {
        marginBottom: '48px',
        textAlign: 'center',
        fontWeight: 400,
        fontSize: '24px',
    },
    continueButton: {
        backgroundColor: '#20559A',
        color: '#FFFFFF',
        width: '100%',
        height: '55px',
        maxWidth: '500px',
        marginTop: '64px',
        fontSize: '16px',
        fontWeight: 700,
        cursor: 'pointer',
    },
    'body': {
        backgroundColor: '#EBD093',
    }
})


export default HomeComponent;