import HCaptcha from '@hcaptcha/react-hcaptcha';
import React, { useEffect, useState } from 'react';

export interface HCaptchaToken {
    token: string;
    ekey: string;
}

const HCaptchaField = (props: {
    name: string,
    value?: string,
    onChange?: (token: string) => void;
}) => {
    const { onChange } = props;
    const [hcaptchaResponse, setResponse] = useState<HCaptchaToken>();

    useEffect(() => {
        console.log(hcaptchaResponse);
        if (hcaptchaResponse && onChange) {
            console.log(hcaptchaResponse);
            onChange(hcaptchaResponse.token);
        }
    }, [hcaptchaResponse, onChange]);

    const handleVerificationSuccess = (token: string, ekey: string) => {
        setResponse({
            token,
            ekey
        });
    }

    return (
        <HCaptcha
            sitekey="910ae7db-a0f6-458b-a003-d3a32cc1e706"
            onVerify={handleVerificationSuccess}
        />

    );
}

export default HCaptchaField;