import axios from 'axios';
import { Manifest } from '../Contexts/ManifestContext';
import configuration from '../../configuration';


export enum SeatType {
    BOOKED = "Booked",
    SELECTED = "Selected",
    EMPTY = "Default",
    BLANK = "Blank",
};

export interface Ark {
    sections: Section[],
    booked: Seat[],
    selected?: Seat,
}

export interface Passenger {
    bibleVerse: string,
    column: string,
    country: string,
    id: number,
    item: string,
    name: string,
    price: string,
    row: number,
    section: number,
    trade: string,
}

export interface Section {
    id: number,
    price: number,
    name: string,
    columns: string[],
    rows: number[],
}

export interface Seat {
    section: number,
    row: number,
    column: string,
    price: number,
}

export interface Donation {
    amount: number;
    created_at: string;
    id: number;
    image: Image;
    message: string;
    paid_at: null;
    payment_info: null;
    payment_token: null;
    userName: string;
}

export interface Image {
    created_at: string;
    id: number;
    name: string;
    url: string;
}


axios.defaults.withCredentials = true;

export const getArk = async (): Promise<Ark> => {
    const { data } = await axios.get<Ark>(`${configuration.apiUrl}/ark/ark`);
    return data;
}

export const getPassengers = async (): Promise<Passenger[]> => {
    const { data } = await axios.get<Passenger[]>(`${configuration.apiUrl}/ark/seats`);
    return data;
}

export type ReserveResponse = {
    success: boolean;
    seatReserved: boolean;
    message: string;
    booked: Seat[];
}

export const reserveSeat = async (seat: Seat) => {
    const { data } = await axios.post<ReserveResponse>(`${configuration.apiUrl}/ark/reserve`, seat);
    return data;
}

export interface Payment {
    success: boolean;
    redirect_url: string;
}

export const makePayment = async (manifest: Manifest): Promise<Payment> => {
    const { data } = await axios.post<Payment>(`${configuration.apiUrl}/ark/pay`, manifest);
    return data;
}