import { makeStyles } from "@material-ui/core";
import React from "react";

const Linktree = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.innerdiv}>
        <img
          src={
            "https://yt3.ggpht.com/a/AATXAJySVnNsce8MOTeldpGFihXyPnZbK-IHjUaHGA=s88-c-k-c0xffffffff-no-rj-mo"
          }
          alt="Test"
          className={classes.imageContainer}
        />
        <p>Richard Burnish</p>
        {/* <p>Host of your ass</p> */}
        <div className={classes.buttonGrid}>
          <form
            action="https://www.youtube.com/c/RichardBurnishOfficial?sub_confirmation=1"
            method="get"
            target="_blank"
          >
            <button className={classes.button} type="submit">
              YouTube
            </button>
          </form>
          <form action="https://discord.gg/seamen" method="get" target="_blank">
            <button className={classes.button} type="submit">
              Discord
            </button>
          </form>
          <form
            action="https://www.patreon.com/richardburnish"
            method="get"
            target="_blank"
          >
            <button className={classes.button} type="submit">
              Patreon
            </button>
          </form>
          <form
            action="https://twitter.com/richardoburnish"
            method="get"
            target="_blank"
          >
            <button className={classes.button} type="submit">
              Twitter
            </button>
          </form>
          <form
            action="https://instagram.com/richardoburnish"
            method="get"
            target="_blank"
          >
            <button className={classes.button} type="submit">
              Instagraham
            </button>
          </form>
          <form
            action="https://www.richardburnish.org/collections/all"
            method="get"
            target="_blank"
          >
            <button className={classes.button} type="submit">
              Ark Store
            </button>
          </form>
          <form
            action="https://www.etsy.com/shop/pinkpineapplestudios?section_id=32527132"
            method="get"
            target="_blank"
          >
            <button className={classes.button} type="submit">
              Ark Stickers
            </button>
          </form>
          <form
            action="https://ark.richardburnish.org/ark"
            method="get"
            target="_blank"
          >
            <button className={classes.button} type="submit">
              Ark Manifest
            </button>
          </form>
          <form
            action="https://donate.richardburnish.org/"
            method="get"
            target="_blank"
          >
            <button className={classes.button} type="submit">
              Fax Donations
            </button>
          </form>
          <button
            onClick={() => {
              window.alert("YOUR IP HAS BEEN REPORTED TO THE POLICE");
            }}
            className={classes.buttonPink}
          >
            Onlyfans
          </button>
        </div>
        <svg
          width="112"
          height="20"
          viewBox="0 0 112 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.51663 0.722781C8.13043 0.0276275 7.12632 0.0276275 6.74013 0.722781L0.097543 12.7721C-0.211414 13.39 0.252022 14.0852 0.947176 14.0852H5.42706V18.6423C5.42706 19.1057 5.81325 19.4919 6.27669 19.4919H8.90283C9.36626 19.4919 9.75246 19.1057 9.75246 18.6423V14.0852H8.51663C7.97595 14.0852 7.58976 13.699 7.51252 13.2356C7.51252 13.0811 7.51252 12.9266 7.58976 12.7706L11.2972 6.05075L8.51663 0.722781V0.722781Z"
            fill="#39E09B"
          />
          <path
            d="M14.3801 0.722781C14.7663 0.0276275 15.7704 0.0276275 16.1566 0.722781L22.7992 12.7721C23.1081 13.39 22.6447 14.0852 21.9495 14.0852H17.5469V18.6423C17.5469 19.1057 17.1607 19.4919 16.6965 19.4919H13.9166C13.4532 19.4919 13.067 19.1057 13.067 18.6423V14.0852H14.3028C14.8435 14.0852 15.2297 13.699 15.3069 13.2356C15.3069 13.0811 15.3069 12.9266 15.2297 12.7706L11.5222 6.05229L14.3801 0.722781V0.722781Z"
            fill="#28BF7B"
          />
          <path
            d="M35.9421 19.2486H38.0373V1.64893H35.9421V9.7969H35.8955C35.1505 8.65619 33.6373 7.93451 32.0543 7.93451C28.6554 7.93451 26.4438 10.4255 26.4438 13.7312C26.4438 16.9904 28.6322 19.5279 32.0543 19.5279C33.5442 19.5279 35.0807 18.8528 35.8955 17.6655H35.9421V19.2486ZM32.3104 17.5724C30.0755 17.5724 28.6787 15.9661 28.6787 13.7312C28.6787 11.4963 30.0755 9.89002 32.3104 9.89002C34.5453 9.89002 35.9421 11.4963 35.9421 13.7312C35.9421 15.9661 34.5453 17.5724 32.3104 17.5724Z"
            fill="#3D3B3C"
          />
          <path
            d="M43.4458 5.55996V3.20868H41.3972V5.55996H43.4458ZM43.4691 19.2486H41.3739V8.21387H43.4691V19.2486Z"
            fill="#3D3B3C"
          />
          <path
            d="M48.8988 8.21387H46.8036V19.2486H48.8988V13.5683C48.8988 11.0075 50.2258 9.89002 51.9718 9.89002C53.2987 9.89002 54.486 10.6583 54.486 12.7302V19.2486H56.5812V12.1482C56.5812 9.2149 54.7188 7.93451 52.577 7.93451C50.8776 7.93451 49.4808 8.74931 48.9454 9.9133H48.8988V8.21387Z"
            fill="#3D3B3C"
          />
          <path
            d="M61.8346 1.64893H59.7394V19.2486H61.8346V13.5217L66.9562 19.2486H69.936L64.3489 13.2423L69.4937 8.23715H66.5837L61.8346 13.0095V1.64893Z"
            fill="#3D3B3C"
          />
          <path
            d="M74.7213 10.0297H77.7244V8.21387H74.7213V5.11764H72.6261V8.21387H70.205V10.0297H72.6261V15.7798C72.6261 17.5491 72.6727 19.5279 75.9086 19.5279C76.3277 19.5279 77.3054 19.4348 77.7943 19.1554V17.2465C77.3752 17.5026 76.7932 17.5724 76.3044 17.5724C74.7213 17.5724 74.7213 16.2687 74.7213 15.0349V10.0297Z"
            fill="#3D3B3C"
          />
          <path
            d="M82.5228 8.21387H80.4276V19.2486H82.5228V13.7079C82.5228 11.2868 83.6403 10.1694 85.6889 10.1694C86.0148 10.1694 86.364 10.2392 86.7132 10.3323L86.8064 8.07419C86.527 7.98107 86.2011 7.93451 85.8752 7.93451C84.5016 7.93451 83.1048 8.74931 82.5694 9.9133H82.5228V8.21387Z"
            fill="#3D3B3C"
          />
          <path
            d="M90.2178 14.4994H99.0641V13.7545C99.0641 10.9143 97.5742 7.93451 93.6865 7.93451C90.3807 7.93451 87.9829 10.4255 87.9829 13.7312C87.9829 17.0137 90.1712 19.5279 93.6632 19.5279C95.7817 19.5279 97.388 18.783 98.552 17.2698L96.969 16.0592C96.1542 17.0602 95.223 17.7121 93.9426 17.7121C92.0103 17.7121 90.2178 16.4317 90.2178 14.4994ZM96.8293 12.8233H90.2178C90.2178 11.3567 91.568 9.61066 93.6399 9.61066C95.7584 9.61066 96.7827 10.9842 96.8293 12.8233Z"
            fill="#3D3B3C"
          />
          <path
            d="M103.154 14.4994H112V13.7545C112 10.9143 110.51 7.93451 106.622 7.93451C103.317 7.93451 100.919 10.4255 100.919 13.7312C100.919 17.0137 103.107 19.5279 106.599 19.5279C108.717 19.5279 110.324 18.783 111.488 17.2698L109.905 16.0592C109.09 17.0602 108.159 17.7121 106.878 17.7121C104.946 17.7121 103.154 16.4317 103.154 14.4994ZM109.765 12.8233H103.154C103.154 11.3567 104.504 9.61066 106.576 9.61066C108.694 9.61066 109.719 10.9842 109.765 12.8233Z"
            fill="#3D3B3C"
          />
        </svg>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  innerdiv: {
    margin: "40px",
    width: "90%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  imageContainer: {
    borderRadius: "100px",
  },
  buttonGrid: {
    display: "grid",
    gridTemplateRows: "5fr",
    gridTemplateColumns: "1fr",
    gridGap: "16px",
    maxWidth: "680px",
    width: "100%",
    marginBottom: "48px",
  },
  button: {
    backgroundColor: "#545454",
    height: "56px",
    width: "100%",
    color: "white",
    fontSize: "16px",
    cursor: "pointer",
    outline: "black",
  },
  buttonPink: {
    backgroundColor: "#C80078",
    height: "56px",
    width: "100%",
    color: "white",
    fontSize: "16px",
    cursor: "pointer",
    outline: "black",
  },
});

export default Linktree;
