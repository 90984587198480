import axios, { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import configuration from '../configuration';


export interface CreateCommunityBoardListingRequest {
    item_name: string;
    description: string;
    price: number;
    contact_name: string;
    contact_phone: string;
    media_id: number;    
    captcha: string;
}

async function createCommunityBoardListing(request: CreateCommunityBoardListingRequest) {
    const { data } = await axios.post<string>(`${configuration.apiUrl}/community-board`, request);
    return data;
}

export function useCreateCommunityBoardListing(    
    options?: Omit<UseMutationOptions<string, AxiosError, CreateCommunityBoardListingRequest>, 'mutationKey' | 'mutationFn'>
) {    
    return useMutation<string, AxiosError, CreateCommunityBoardListingRequest>(
        async(request) => await createCommunityBoardListing(request),
        options
    );
}