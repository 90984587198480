import { CircularProgress, InputLabel, makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import qs from "query-string";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Controller, useForm } from "react-hook-form";
import { Link, useLocation } from "react-router-dom";
import AgreementModal from "../AgreementModal";
import { DonationRequest, DonationType, useCreateDonation } from "../hooks/useCreateDonation";
import Leaderboard from "../leaderBoard";
import Battery from "./Battery";
import NuclearBattery from "./NuclearBattery";

interface DonationForm {
  amount: number;
  promocode?: string;
}

const ShockPage = () => {
  const location = useLocation();
  const queryString = qs.parse(location.search);
  const code = (queryString?.code as string) || undefined;
  const dollarValues = [10, 12, 14, 16, 100];
  const classes = useStyles();
  const [openAgreement, setOpenAgreement] = useState<boolean>(false);
  const [power, setPower] = useState<number>(100);
  const [price, setPrice] = useState<number>(10);
  const [slotOne, setSlotOne] = useState<boolean>(false);
  const [slotTwo, setSlotTwo] = useState<boolean>(false);
  const [slotThree, setSlotThree] = useState<boolean>(false);
  const [slotFour, setSlotFour] = useState<boolean>(false);
  const [slotFive, setSlotFive] = useState<boolean>(false);

  const {
    control,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<DonationForm>({
    mode: "onChange",
    defaultValues: {
      amount: dollarValues[0],
    },
  });

  const donationMutation = useCreateDonation({
    onSuccess: (response) => {
      if (response.success) {
        window.location.href = response.redirectUrl;
      }
    },
  });

  const onSubmit = handleSubmit(async (form) => {
    const { amount, promocode } = form;
    const donation: DonationRequest = {
      amount,
      code,
      type: DonationType.SHOCK,
      promocode,
    };

    donationMutation.mutate(donation);
  });

  const handleOpenAgreement = async () => {
    const isValid = true;
    if (isValid) {
      setOpenAgreement(true);
    }
  };

  const handleCloseAgreement = () => {
    setOpenAgreement(false);
  };

  const handleClick = (slot: number) => {
    switch (slot) {
      case 1:
        setPower(100);
        setPrice(dollarValues[0]);
        setValue("amount", dollarValues[0]);
        setSlotTwo(false);
        setSlotThree(false);
        setSlotFour(false);
        setSlotFive(false);
        break;
      case 2:
        if (!slotTwo) {
          setPower(200);
          setPrice(dollarValues[1]);
          setValue("amount", dollarValues[1]);
          setSlotTwo(true);
        } else {
          setPower(100);
          setPrice(dollarValues[0]);
          setValue("amount", dollarValues[0]);
          setSlotTwo(false);
          setSlotThree(false);
          setSlotFour(false);
          setSlotFive(false);
        }
        break;
      case 3:
        if (!slotThree) {
          setPower(300);
          setPrice(dollarValues[2]);
          setValue("amount", dollarValues[2]);
          setSlotTwo(true);
          setSlotThree(true);
        } else {
          setPower(200);
          setPrice(dollarValues[1]);
          setValue("amount", dollarValues[1]);
          setSlotThree(false);
          setSlotFour(false);
          setSlotFive(false);
        }
        break;
      case 4:
        if (!slotFour) {
          setPower(400);
          setPrice(dollarValues[3]);
          setValue("amount", dollarValues[3]);
          setSlotTwo(true);
          setSlotThree(true);
          setSlotFour(true);
        } else {
          setPower(300);
          setPrice(dollarValues[2]);
          setValue("amount", dollarValues[2]);
          setSlotFour(false);
          setSlotFive(false);
        }
        break;
      case 5:
        if (!slotFive) {
          setPower(3000);
          setPrice(dollarValues[4]);
          setValue("amount", dollarValues[4]);
          setSlotTwo(true);
          setSlotThree(true);
          setSlotFour(true);
          setSlotFive(true);
        } else {
          setPower(400);
          setPrice(dollarValues[3]);
          setValue("amount", dollarValues[3]);
          setSlotFive(false);
        }
        break;
      default:
        setPower(100);
        setPrice(dollarValues[0]);
        setSlotOne(true);
        break;
    }
  };

  return (
    <div className={classes.root}>
      <Helmet>
        <style type="text/css">{"body { background-color: #1b1537; }"}</style>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=VT323&display=swap" />
      </Helmet>
      <div className={classes.container}>
        <form onSubmit={onSubmit} noValidate>
          <AgreementModal
            open={openAgreement}
            handleClose={handleCloseAgreement}
            isSubmitting={isSubmitting}
            onSubmit={onSubmit}
          />
          <div className={classes.donateContainer}>
            <div className={classes.donateHeader}>
              <div className={classes.donateHeaderImage}>
                <div className={classes.donateHeaderImageOverlay}>
                  <Link to={"/"}>
                    <div className={classes.donateHeaderPortrait} />
                  </Link>
                  <div className={classes.donateHeaderTextContainer}>
                    <div className={classes.donateHeaderText}>Ark Hacker Donations</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.donateMoneyContainer}>
              <div className={classes.donateMoneyContainerInside}>
                <div className={classes.batteryContainer}>
                  <div className={classes.batteryHeader}>
                    <div className={classes.batteryHeaderScreen}>
                      <p className={classes.batteryHeaderScreenFont}>{power < 3000 ? `OUTPUT:${power}v` : `DEATH`}</p>
                      <p className={classes.batteryHeaderScreenFont}>{`$${price}.00`}</p>
                    </div>
                    <div className={classes.batteryBody}>
                      <div onClick={() => handleClick(1)} style={{ cursor: "pointer" }}>
                        <Battery enabled={power >= 100} />
                      </div>
                      <div onClick={() => handleClick(2)} style={{ cursor: "pointer" }}>
                        <Battery enabled={power >= 200} />
                      </div>
                      <div onClick={() => handleClick(3)} style={{ cursor: "pointer" }}>
                        <Battery enabled={power >= 300} />
                      </div>
                      <div onClick={() => handleClick(4)} style={{ cursor: "pointer" }}>
                        <Battery enabled={power >= 400} />
                      </div>
                      <div onClick={() => handleClick(5)} style={{ cursor: "pointer" }}>
                        <NuclearBattery enabled={power >= 3000} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={classes.donateMoneyContainerInputContainer}></div>
              </div>
              <div className={classes.donateButtonContainer}>
                <Button
                  style={{ display: "flex", flexDirection: "column" }}
                  disabled={donationMutation.isLoading}
                  fullWidth
                  type="button"
                  onClick={handleOpenAgreement}
                  className={classes.donateButton}
                  variant="contained"
                  color="primary"
                >
                  {donationMutation.isLoading ? (
                    <CircularProgress thickness={5.0} color={"inherit"} size={30} />
                  ) : (
                    "Donate to Richard"
                  )}
                </Button>
                <InputLabel className={classes.fileUploadLabel}>
                  * All donations must comply with the{" "}
                  <a href="https://www.youtube.com/about/policies/#community-guidelines">Community-Guidelines</a>.
                  Making a donation in no way guarantees that your submission will be shown on the program. All
                  donations are subject to Richard's discretion and God's discretion, as well. Amen.
                </InputLabel>
                <div className={classes.leaderboardContainer}>
                  <Leaderboard />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  root: {
    fontFamily: "Nunito",
    fontWeight: 400,
    maxWidth: "640px",
    margin: "0 auto",
  },
  container: {
    marginTop: "28px",
    display: "flex",
    maxHeight: "6000px",
    flexDirection: "column",
  },

  batteryContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  batteryHeader: {
    width: "95%",
    padding: "18px",
    backgroundColor: "#FAB92D",
    borderRadius: "4px",
    filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
  },
  batteryHeaderScreen: {
    padding: "18px",
    backgroundColor: "#18162F",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    border: "3px solid #8260e5",
  },
  batteryHeaderScreenFont: {
    color: "#BB0000",
    fontFamily: "VT323",
    fontSize: "45px",
    textTransform: "uppercase",
    margin: 0,
  },
  batteryBody: {
    marginTop: "32px",
    marginBottom: "32px",
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
    gridGap: "2%",
  },

  promoCodeContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    padding: "18px",
  },
  promoTextInputContainer: {
    display: "flex",
    widht: "100%",
    flexDirection: "row",
  },
  donateContainer: {
    display: "flex",
    flexDirection: "column",
    flex: "0 0 auto",
    boxSizing: "inherit",
  },
  donateHeader: {
    maxWidth: "640px",
    zIndex: 200,
    position: "relative",
  },
  donateHeaderImage: {
    maxWidth: "640px",
    position: "relative",
    backgroundImage: "url(https://cdn.streamelements.com/static/user/tipping_default.jpg)",
    height: "200px",
    backgroundPosition: "center center",
  },
  promoGiftContainer: {
    marginTop: "24px",
    textAlign: "center",
    padding: "24px",
    color: "white",
    backgroundColor: "#1b1537",
  },
  donateHeaderImageOverlay: {
    paddingLeft: "30px",
    paddingRight: "30px",
    marginBottom: "-20px",
    flexDirection: "row",
    alignItems: "center",
    position: "absolute",
    bottom: "0px",
    display: "flex",
    WebkitBoxAlign: "center",
  },
  donateHeaderPortrait: {
    backgroundImage:
      "url(https://yt3.ggpht.com/a/AATXAJySVnNsce8MOTeldpGFihXyPnZbK-IHjUaHGA=s88-c-k-c0xffffffff-no-rj-mo)",
    backgroundSize: "cover",
    height: "110px",
    zIndex: 200,
    width: "110px",
    cursor: "pointer",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    borderRadius: "50%",
    borderWidth: "4px",
    borderStyle: "solid",
    borderColor: "white",
    borderImage: "initial",
    overflow: "hidden",
    flex: "0 0 auto",
    boxSizing: "border-box",
    display: "block",
  },
  donateHeaderTextContainer: {
    marginLeft: "30px",
    alignItems: "flex-start",
    display: "flex",
    WebkitBoxPack: "center",
    justifyContent: "center",
    flexDirection: "column",
    WebkitBoxAlign: "center",
    boxSizing: "border-box",
  },
  donateHeaderText: {
    color: "white",
    textShadow: "rgba(0, 0, 0, 0.5) 0px 2px 4px",
    textAlign: "left",
    fontSize: "20px",
    letterSpacing: "1.5px",
    overflowWrap: "break-word",
    wordBreak: "break-word",
    paddingLeft: "18px",
    paddingRight: "18px",
    boxSizing: "border-box",
    display: "block",
  },
  donateMoneyContainer: {
    paddingTop: "64px",
    backgroundColor: "white",
    width: "100%",
    maxWidth: "640px",
    position: "relative",
    flex: "1 1 0%",
    overflow: "hidden",
    boxSizing: "border-box",
    display: "block",
  },
  donateMoneyContainerInside: {
    opacity: 1,
    backgroundColor: "white",
    paddingLeft: "18px",
    paddingRight: "18px",
    display: "flex",
    flexDirection: "column",
    margin: "0px !important",
    boxSizing: "border-box",
  },
  donateMoneyContainerInputContainer: {
    boxSizing: "border-box",
    display: "block",
  },
  donateMoneyContainerInputNameContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    WebkitBoxPack: "justify",
    boxSizing: "border-box",
    width: "100%",
  },
  donateMoneyPresetsContainer: {
    display: "grid",
    gridTemplateColumns: "5fr 1fr",
    gridGap: "24px",
    marginTop: "28px",
    width: "100%",
  },
  donateMoneyPresetContainerInterior: {
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    marginLeft: "18px",
    marginBottom: "24px",
  },
  dollarAmmountInput: {
    paddingRight: "18px",
  },
  recordControlsContainer: {
    display: "grid",
    marginTop: "64px",
    marginBottom: "64px",
    gridTemplateColumns: "1fr 1fr",
    gridGap: "64px",
    padding: "64px",
    margin: "16px",
    backgroundColor: "#5448873b",
    borderRadius: "4px",
  },
  fileUploadContainer: {
    border: "1px solid black",
    height: "300px",
    marginLeft: "18px",
    marginRight: "18px",
    marginTop: "28px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  fileUploadLabel: {
    marginTop: "24px",
    textAlign: "center",
    lineHeight: "24px",
  },
  donateButtonContainer: {
    marginTop: "32px",
    paddingLeft: "18px",
    paddingRight: "18px",
    paddingBottom: "32px",
  },
  donateButton: {
    width: "100%",
    height: "48px",
    background: "linear-gradient(284deg, rgb(112, 87, 220), rgb(87, 112, 220))!important",
  },
  recordButton: {
    width: "100%",
    background: "red!important",
    height: "48px",
    color: "white",
  },
  playButton: {
    width: "100%",
    background: "green!important",
    height: "48px",
    color: "white",
  },
  leaderboardContainer: {
    width: "100%",
    margin: "0 auto",
    marginTop: "32px",
  },
  img: {
    height: "100%",
    width: "100%",
    objectFit: "cover",
  },
  error: {
    color: "#FF0000",
    "& > input,select": {
      color: "#FF0000",
      borderColor: "#FF0000",
    },
    "& p": {
      margin: "8px 0px",
      padding: 0,
    },
  },
});

export default ShockPage;
