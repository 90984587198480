import { Button, Checkbox, CircularProgress, FormControlLabel, makeStyles, Modal } from "@material-ui/core";
import React, { useRef, useState } from "react";

type Props = {
  open: boolean;
  handleClose: () => void;
  isSubmitting: boolean;
  onSubmit: () => void;
};

const AgreementModal = (props: Props) => {
  const { handleClose, isSubmitting, open, onSubmit } = props;
  const classes = useStyles();
  const checkboxRef = useRef<HTMLInputElement>(null);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);

  const handleChange = () => {
    if (checkboxRef.current) {
      setButtonDisabled(!checkboxRef.current.checked);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <div className={classes.paper}>
        <h2 id="simple-modal-title">Donation Agreement</h2>
        <p id="simple-modal-description">
          Making a donation in no way guarantees that your submission will be shown on the show. This will depend on the
          length of the show and the number of donations that have been received.
        </p>
        <FormControlLabel control={<Checkbox inputRef={checkboxRef} />} label="I understand" onChange={handleChange} />

        <div className={classes.buttonContainer}>
          <Button
            disabled={buttonDisabled}
            className={classes.button}
            type="button"
            onClick={onSubmit}
            variant="contained"
            color="primary"
            size="large"
            fullWidth
          >
            {isSubmitting ? <CircularProgress thickness={5.0} color={"inherit"} size={30} /> : "I UNDERSTAND"}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    fontFamily: "Nunito",
    fontWeight: 400,
    position: "absolute",
    minWidth: 300,
    maxWidth: 500,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  buttonContainer: {
    marginTop: "32px",
    paddingLeft: "18px",
    paddingRight: "18px",
    paddingBottom: "32px",
  },
  button: {
    width: "100%",
    background: "linear-gradient(284deg, rgb(112, 87, 220), rgb(87, 112, 220))!important",
  },
}));

export default AgreementModal;
