import { CircularProgress, makeStyles } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { ArkContext, arkContext } from '../Contexts/ArkContext';
import { getArk, reserveSeat, SeatType, Section } from '../Services/service';
import ConfirmOverlay from './ConfirmOverlay';

const ArkComponent = () => {

    const classes = useStyles();
    const { ark, setArk } = useContext<ArkContext>(arkContext);    
    const [showOverlay, setShowOverlay] = useState<boolean>(false);

    const { selected, booked, sections } = ark;

    const arkLength = ark.sections.length;

    useEffect(() => {
        if (arkLength === 0) {
            getArk().then((data) => {
                setArk(data);
            })    
        }
    }, [arkLength, setArk]);

    useEffect(() => {          
        if(selected !== undefined) {
            setShowOverlay(true);
        } else {
            setShowOverlay(false);
        }
    }, [selected]);

    const handleSeatClick = (section: number, row: number, column: string) => async () => {
        const price = sections.find((s) => s.id === section)?.price || 99.99;

        const selected = {
            section,
            row,
            column,
            price,
        };

        const response = await reserveSeat(selected);
        if (response.seatReserved) {
            setArk({...ark, booked: response.booked, selected});
        } else {            
            setArk({...ark, booked: response.booked});
        }
        
    }

    const getSeatType = (section: number, row: number, column: string): SeatType => {
        var type: SeatType = SeatType.EMPTY;
        
        const isReserved = booked.some((s) => s.section === section && s.row === row && s.column === column);
        if (isReserved) {
            type = SeatType.BOOKED;
        }

        const isSelected = (selected?.section === section && selected.row === row && selected.column === column);
        if (isSelected) {
            type = SeatType.SELECTED;
        }

        return type;
    }

    const getSeatSVG = (section: number, row: number, column: string) => {
        const seatType = getSeatType(section, row, column);        

        switch (section) {
            case 1:
                return `/images/First${seatType.toString()}.svg`;
            case 2:
                return `/images/Second${seatType.toString()}.svg`;
            case 3:
                return `/images/Third${seatType.toString()}.svg`;
        }
        return '';
    }

    const tableCell = (section: number, row: number, column: string) => {        
        if (column === '') {
            return <td style={{width: '40px'}}></td>
        }

        return <td onClick={handleSeatClick(section, row, column)} key={`${section}-${row}-${column}`}>
            <img style={{cursor: 'pointer'}}src={getSeatSVG(section, row, column)} alt="" />                                     
        </td>
    }

    if (sections.length === 0) {
        return <div className={classes.root} ><CircularProgress /></div>
    }

    return  <div className={classes.root} >
                <Helmet>
                    <style type="text/css"> 
                        {'body { background-color: #EBD093; }'}
                    </style>        
                </Helmet>            

                <h1 className={classes.title} >Seat Select</h1>
                <table>
                    <thead className={classes.header} >
                        <tr>
                            <th />
                            {sections[0].columns.map((c) => (
                                <th key={c} className={classes.yAxis} >{c}</th>
                            ))}
                        </tr>
                    </thead>
                                
                    {sections.map((s: Section) => (
                        <tbody key={s.id} className={classes.section} >
                            {s.rows.map((r: number) => (
                                <tr key={`${s.id}-${r}`} >
                                    <td className={classes.xAxis} >{r}</td>
                                    {s.columns.map((c: string) => (
                                        tableCell(s.id, r, c)
                                    ))}

                                </tr>
                                
                            ))}
                            <tr>
                                <td className={classes.spacer} colSpan={s.columns.length} />
                            </tr>
                        </tbody>
                    ))}
                    

                </table>
                <ConfirmOverlay show={showOverlay} selected={selected} />
            </div>        
};

const useStyles = makeStyles({
    root: {
        backgroundColor: '#EBD093',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '88px',
        fontFamily: 'Nunito',
        fontWeight: 300,
    },
    title: {
        fontSize: '24px',
        lineHeight: '33px',
        fontWeight: 400,
    },
    header: {

    },
    section: {
        marginBottom: '48px',        
    },
    icon: {
        height: '42px',
        width: '42px',
        padding: '4px',
    },
    xAxis: {
        fontSize: '16px',
        paddingRight: '4px',
        fontWeight: 700,
    },
    yAxis: {
        fontSize: '16px',
        paddingBottom: '4px',
        fontWeight: 700,
    },
    spacer: {
        height: '48px',
    },
    'body': {
        margin: 0,
        padding: 0,
        backgroundColor: '#EBD093',
    }
});


export default ArkComponent;