import React, { useEffect } from 'react';
// import SwipeableViews from 'react-swipeable-views';
import { CircularProgress } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import { fetchLeaderBoard, LeaderBoard } from './services';

interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

export default function FullWidthTabs() {
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const [leaderBoard, setLeaderBoard] = React.useState<LeaderBoard>();

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    useEffect(() => {
        fetchLeaderBoard().then((data) => {
            setLeaderBoard(data);
        });
    }, []);

    if (leaderBoard === undefined) {
        return <CircularProgress />
    }
    
    const values = (value === 0 && leaderBoard.week) || (value === 1 && leaderBoard.month) || (value === 2 && leaderBoard.year) || [];

    return (
        <div>
            <AppBar position="static" color="default">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    centered
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                >
                    <Tab label="1 Week" {...a11yProps(0)} />
                    <Tab label="1 Month" {...a11yProps(1)} />
                    <Tab label="1 Year" {...a11yProps(2)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={value} dir={theme.direction}>
                <div className={classes.nameContainer}>
                    {values.map((board, idx) => (
                        <div className={classes.nameSide}>
                            <div className={classes.personContainer}>
                                <div className={classes.personRank}>{idx+1}.</div>
                                    <div className={classes.personNameRankContainer}>
                                        <p className={classes.nameTextStyle}>{board.name.trim() === '' ? 'Anonymous' : board.name }</p>
                                        <p className={classes.donationsTextStyle}>${board.totalDonations.toFixed(2)}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
                One Month
            </TabPanel>
            <TabPanel value={value} index={2} dir={theme.direction}>
                One Year
            </TabPanel>
        </div >

    );

}
const useStyles = makeStyles({
    nameContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    nameSide: {
        display: 'flex',
        flexDirection: 'column',
        width: '50%',
    },
    personContainer: {
        height: '48px',
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '32px',
    },
    personRank: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '48px',
        height: '48px',
        fontSize: '26px',
        fontWeight: 'bolder',
    },
    personNameRankContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    nameTextStyle: {
        color: 'Black',
        fontWeight: 'bold',
        margin: 0,
    },
    donationsTextStyle: {
        color: 'grey',
        margin: 0,
    },

}
)