import axios from "axios";
import configuration from "../configuration";

export type LeaderBoard = {
  week: LeaderBoardResults[];
  month: LeaderBoardResults[];
  year: LeaderBoardResults[];
};

export type LeaderBoardResults = {
  name: string;
  totalDonations: number;
};

export type MediaUploadResponse = {
  id: number;
  url: string;
};


export async function validatePromocode(promocode: string): Promise<boolean> {
  const { data } = await axios.get<boolean>(`${configuration.apiUrl}/donations/promo_code/${promocode}/validate`);
  return data;
}

export async function uploadMedia(media: Blob, filename: string) {
  const formData = new FormData();
  formData.append("file", media, filename);
  const { data } = await axios.post<MediaUploadResponse>(`${configuration.apiUrl}/donations/upload_media`, formData);
  return data;
}

export async function fetchLeaderBoard(): Promise<LeaderBoard> {
  const { data } = await axios.get<LeaderBoard>(`${configuration.apiUrl}/donations/leader_board`);
  return data;
}
