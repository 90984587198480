import axios from "axios";
import { useQuery, UseQueryOptions } from "react-query";
import configuration from "../configuration";

export async function fetchIsOnline(): Promise<boolean> {
    const { data } = await axios.get<boolean>(`${configuration.apiUrl}/donations/is_online`);
    return data;
}


export function useIsOnline(    
    options?: Omit<UseQueryOptions<boolean>, "queryKey" | "queryFn">
) {    
    return useQuery<boolean>(
        'isOnline',
        async() => await fetchIsOnline(),
        options
    );
}