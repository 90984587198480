import axios, { AxiosError } from "axios";
import { MutationOptions, useMutation } from "react-query";
import configuration from "../configuration";
import { MediaUploadResponse } from "../Donate/services";

export interface MediaUploadRequest {
    file: File;
    filename: string;
}

async function uploadMedia(request: MediaUploadRequest) {
    const formData = new FormData();
    formData.append('file', request.file, request.filename);
    const { data } = await axios.post<MediaUploadResponse>(`${configuration.apiUrl}/donations/upload_media`, formData);
    return data;
}

export function useMediaUpload(
    options?: MutationOptions<MediaUploadResponse, AxiosError, MediaUploadRequest>,
) {
    return useMutation<MediaUploadResponse, AxiosError, MediaUploadRequest>((request) => uploadMedia(request), options);
}