import { Button, Snackbar } from "@material-ui/core";
import React, { useState } from "react";

interface Props {
  value: string;
}

const CopyToClipboardButton = (props: Props) => {
  const { value } = props;
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
    navigator.clipboard.writeText(value.toString());
  };

  return (
    <div>
      <Button fullWidth onClick={handleClick} variant="contained" size="large" color="primary">
        🔗 Gift this shock to a friend instead
      </Button>
      <Snackbar
        open={open}
        onClose={() => setOpen(false)}
        autoHideDuration={2000}
        message={`Copied share link to clipboard`}
      />
    </div>
  );
};

export default CopyToClipboardButton;
