import { makeStyles } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { Passenger } from '../Services/service';

interface IProps {
    show: boolean;
    selected?: Passenger;
}

const useStyles = makeStyles({
    root: {
        zIndex: 2,
        position: 'fixed',
        bottom: 0,
        backgroundColor: '#FEE9BB',
        color: '#000000',
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        apgnItems: 'center',
        padding: '16px',
        boxSizing: 'border-box',
        boxShadow: '0px 0px 24px rgba(0, 0, 0, 0.5)',
        flexDirection: 'column',
    },
    info: {
        fontSize: '16px',
        textAlign: 'left',
        fontFamily: 'Nunito',
        '& p': {
            padding: 0,
            margin: 0,
        },
    },
    title: {
        margin: 0,
        pading: 0,

    },
    confirmButton: {
        backgroundColor: '#20559A',
        border: 0,
        color: '#FFFFFF',
        padding: '16px 24px',
        height: '55px',
        fontSize: '16px',
        fontWeight: 700,
        width: '100%',
        marginTop: '24px',
        cursor: 'pointer',
    },
    welcome: {
        fontSize: '16px',
        textAlign: 'center',
        fontFamily: 'Nunito',
    }

});

const ConfirmOverlay = (props: IProps) => {

    const classes = useStyles();
    const { selected, show } = props;

    const selectedSeats = `${selected?.row}${selected?.column}`;

    const setClass = () => {
        switch (selected?.section) {
            case 1:
                return "First Class";
            case 2:
                return "Second Class";
            case 3:
                return "Third Class";
            default:
                return "It's a miracle!";
        }
    }

    const seatClass = setClass();

    if (!show) {
        return <></>
    }

    return <div className={classes.root}>
        {selected &&
            <div className={classes.info} >
                <h2>{`${selected?.name} (${seatClass}) `}</h2>
                <h5 className={classes.title}>{`Trade: ${selected?.trade}`}</h5>
                <h5 className={classes.title}>{`Bringing: ${selected?.item}`}</h5>
                <h5 className={classes.title}>{`Bible Verse: "${selected?.bibleVerse}"`}</h5>
                <h5 className={classes.title}>Orientation: Straight</h5>
                <h5 className={classes.title}>{`${selected?.country}`}</h5>
                <h5>{`Seat: ${selectedSeats}`}</h5>
            </div>
        }
        {!selected &&
            <div className={classes.welcome} >
                <h3>Welcome to The Ark!</h3>
                <p>Select a seat to view its passenger</p>
            </div>
        }
        <div>
            <Link to="/ark/home">
                <button className={classes.confirmButton}>RESERVE YOUR SPACE NOW</button>
            </Link>
        </div>
    </div>

}

export default ConfirmOverlay;