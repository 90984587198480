import { makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import qs from "query-string";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, useLocation } from "react-router-dom";
import { capturePayment } from "../service";
import { processDonation } from "./hooks/useCreateDonation";

const Thankyou = () => {
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    const queryString = qs.parse(location.search);
    if (queryString?.token) {
      capturePayment(queryString.token as string).then(() => {
        if (queryString?.donation_token) {
          processDonation(queryString.donation_token as string);
        }
      });
    } else {
      if (queryString?.donation_token) {
        processDonation(queryString.donation_token as string);
      }
    }
  }, [location.search]);

  return (
    <div className={classes.root}>
      <Helmet>
        <style type="text/css">{"body { background-color: #1b1537; }"}</style>
      </Helmet>
      <div className={classes.container}>
        <div className={classes.donateContainer}>
          <div className={classes.donateHeader}>
            <div className={classes.donateHeaderImage}>
              <div className={classes.donateHeaderImageOverlay}>
                <Link to={"/"}>
                  <div className={classes.donateHeaderPortrait} />
                </Link>
                <div className={classes.donateHeaderTextContainer}>
                  <div className={classes.donateHeaderText}>Richard Burnish</div>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.donateMoneyContainer}>
            <div className={classes.donateMoneyContainerInside}>
              <div className={classes.donateMoneyContainerInputContainer}>
                <div className={classes.donateMoneyContainerInputNameContainer}>
                  <h1>Thank you!</h1>
                  <p>Your donation and message has been received.</p>
                </div>
              </div>
            </div>
            <div className={classes.donateMoneyPresetsContainer}>
              <div className={classes.donateMoneyPresetContainerInterior}></div>
            </div>
            <div className={classes.tipMessageContainer}></div>

            <div className={classes.donateButtonContainer}>
              <Button type="submit" className={classes.donateButton} variant="contained" color="primary" href="/">
                Donate again
              </Button>
              <div className={classes.leaderboardContainer}></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  root: {
    fontFamily: "Nunito",
    fontWeight: 400,
    maxWidth: "640px",
    margin: "0 auto",
  },
  container: {
    marginTop: "28px",
    display: "flex",
    maxHeight: "6000px",
    flexDirection: "column",
  },
  donateContainer: {
    display: "flex",
    flexDirection: "column",
    flex: "0 0 auto",
    boxSizing: "inherit",
  },
  donateHeader: {
    maxWidth: "640px",
    zIndex: 200,
    position: "relative",
  },
  donateHeaderImage: {
    maxWidth: "640px",
    position: "relative",
    backgroundImage: "url(https://cdn.streamelements.com/static/user/tipping_default.jpg)",
    height: "200px",
    backgroundPosition: "center center",
  },
  donateHeaderImageOverlay: {
    paddingLeft: "30px",
    paddingRight: "30px",
    marginBottom: "-20px",
    flexDirection: "row",
    alignItems: "center",
    position: "absolute",
    bottom: "0px",
    display: "flex",
    WebkitBoxAlign: "center",
  },
  donateHeaderPortrait: {
    backgroundImage:
      "url(https://yt3.ggpht.com/a/AATXAJySVnNsce8MOTeldpGFihXyPnZbK-IHjUaHGA=s88-c-k-c0xffffffff-no-rj-mo)",
    backgroundSize: "cover",
    height: "110px",
    zIndex: 200,
    width: "110px",
    cursor: "pointer",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    borderRadius: "50%",
    borderWidth: "4px",
    borderStyle: "solid",
    borderColor: "white",
    borderImage: "initial",
    overflow: "hidden",
    flex: "0 0 auto",
    boxSizing: "border-box",
    display: "block",
  },
  donateHeaderTextContainer: {
    marginLeft: "30px",
    alignItems: "flex-start",
    display: "flex",
    WebkitBoxPack: "center",
    justifyContent: "center",
    flexDirection: "column",
    WebkitBoxAlign: "center",
    boxSizing: "border-box",
  },
  donateHeaderText: {
    color: "white",
    textShadow: "rgba(0, 0, 0, 0.5) 0px 2px 4px",
    textAlign: "left",
    fontSize: "20px",
    letterSpacing: "1.5px",
    overflowWrap: "break-word",
    wordBreak: "break-word",
    paddingLeft: "18px",
    paddingRight: "18px",
    boxSizing: "border-box",
    display: "block",
  },
  donateMoneyContainer: {
    paddingTop: "20px",
    backgroundColor: "white",
    width: "100%",
    maxWidth: "640px",
    position: "relative",
    flex: "1 1 0%",
    overflow: "hidden",
    boxSizing: "border-box",
    display: "block",
  },
  donateMoneyContainerInside: {
    opacity: 1,
    backgroundColor: "white",
    height: "100%",
    paddingLeft: "18px",
    paddingRight: "18px",
    display: "flex",
    flexDirection: "column",
    margin: "0px !important",
    boxSizing: "border-box",
  },
  donateMoneyContainerInputContainer: {
    boxSizing: "border-box",
    display: "block",
  },
  donateMoneyContainerInputNameContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    WebkitBoxPack: "justify",
    boxSizing: "border-box",
    width: "100%",
  },
  donateMoneyPresetsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    WebkitBoxPack: "justify",
    justifyContent: "space-between",
    boxSizing: "border-box",
    marginTop: "28px",
    width: "100%",
  },
  donateMoneyPresetContainerInterior: {
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    marginLeft: "18px",
  },
  dollarAmmountInput: {
    paddingRight: "18px",
    width: "120px",
  },
  tipMessageContainer: {
    display: "flex",
    paddingLeft: "18px",
    paddingRight: "18px",
    marginTop: "28px",
  },
  fileUploadContainer: {
    border: "1px solid black",
    height: "300px",
    marginLeft: "18px",
    marginRight: "18px",
    marginTop: "28px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  fileUploadLabel: {
    marginLeft: "18px",
    lineHeight: "24px",
  },
  donateButtonContainer: {
    marginTop: "32px",
    paddingLeft: "18px",
    paddingRight: "18px",
    paddingBottom: "32px",
  },
  donateButton: {
    width: "100%",
    background: "linear-gradient(284deg, rgb(112, 87, 220), rgb(87, 112, 220))!important",
  },
  leaderboardContainer: {
    width: "100%",
    margin: "0 auto",
    marginTop: "32px",
  },
  img: {
    height: "100%",
    width: "100%",
    objectFit: "cover",
  },
});

export default Thankyou;
