import { makeStyles } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PaypalButton from '../../Components/PaypalButton';
import { ArkContext, arkContext } from '../Contexts/ArkContext';
import { useManifest } from '../Contexts/ManifestContext';
import { makePayment } from '../Services/service';
    
const ReviewComponent = () => {
    
    const classes = useStyles();
    const { manifest } = useManifest();
    const { ark, setArk } = useContext<ArkContext>(arkContext);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    if (manifest === undefined  || manifest.seat === undefined) {        
        navigate('/ark');
    }

    const handleRemove = () => {
        setArk({...ark, selected: undefined});
    }

    const handlePayClick = async() => {
        if (manifest) {
            setIsLoading(true);
            const result = await makePayment(manifest);
            if (result.success) {
                window.location.href = result.redirect_url;
            }
        }        
    }
    
    const ticketClass = (section: number) => {
        switch (section) {
            case 1:
                return 'First Class Ticket';
            case 2:
                return 'Second Class Ticket';
            case 3: 
                return 'Third Class Ticket';
            default:
                return 'You done messed up';
        }
    }

    return <div className={classes.root} >
        <h1 className={classes.title} >Review and pay</h1>
        <div className={classes.detailsContainer} >
            <div>
                <img src="/images/ticket.png" alt="ticket" />
            </div>
            <div className={classes.detailsSection} >
                <h4 className={classes.mb3} >{ticketClass(manifest?.seat?.section || 0)}</h4>
                <h4 className={classes.mb2} >Details</h4>
                <ul>
                    <li>Name: {manifest?.name}</li>
                    <li>Trade: {manifest?.trade}</li>
                    <li>Country: {manifest?.country}</li>
                    <li>Item: {manifest?.item}</li>
                    <li>Bible Verse: {manifest?.bibleVerse}</li>
                </ul>
                <hr />
                <div className={classes.priceSection} >
                    <span>QTY 1</span>
                    <span>Price {manifest?.seat?.price}</span>
                </div>
                <hr />

                <button onClick={handleRemove} className={classes.removeBtn} >REMOVE</button>
            </div>
        </div>

        <div className={classes.btnContainer} >
            <PaypalButton isLoading={isLoading}  onClick={handlePayClick} />
        </div>
        
        

    </div>
};

const useStyles = makeStyles({
    root: {
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '16px',
        fontFamily: 'Nunito',
        fontWeight: 300,
        maxWidth: '500px',
    },
    title: {
        fontSize: '24px',
        lineHeight: '33px',
        marginBottom: '48px',
    },
    image: {
        marginBottom: '32px',
    },
    priceSection: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '24px 0px',
    },
    removeBtn: {
        color: '#BD2036',
        fontSize: '14px',
        textDecoration: 'underline',
        backgroundColor: 'inherit',
        border: 0,
    },
    detailsContainer: {
        display: 'flex',
        '& h4': {
            margin: 0,
            padding: 0,
            fontSize: '22px',
        },
    },
    detailsSection: {
        marginLeft: '16px',
    },
    totalContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '88px',
        marginBottom: '64px',
    },
    btnContainer: {
        width: '100%',
        marginTop: '16px',
    },
    mb2: {
        marginBottom: '16px',
    },
    mb3: {
        marginBottom: '24px',
    }
});

export default ReviewComponent;