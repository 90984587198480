import { Button, makeStyles } from "@material-ui/core";
import React from "react";
import { useAudioUserMedia } from "./useAudioUserMedia";

interface Props {
  audio: HTMLAudioElement;
}

const VoiceComponent = (props: Props) => {
  const classes = useStyles();

  const { audioState, getReady, play, record, stopPlaying, stopRecording, timeleft } = useAudioUserMedia(props.audio, { timer: 20 });

  const handleRecord = () => {
    if (audioState === 'STOPPED') {            
      record();
    } else if (audioState === 'RECORDING') {
      stopRecording();
    }    
  }

  const handlePlay = () => {
    if (audioState === 'STOPPED') {
      play();
    } else if (audioState === 'PLAYING') {
      stopPlaying();
    }    

  }  

  return (
    <div className={classes.recordControlsContainer}>
      <Button
        onClick={handleRecord}
        variant="contained"
        className={classes.recordButton}
        type="button"
        disabled={audioState === 'PLAYING'}
      >
        {" "}
        <span>{ audioState === 'RECORDING' ? `(${timeleft}) STOP` : 'RECORD' }</span>
      </Button>
      <Button
        onClick={handlePlay}
        variant="contained"
        className={classes.playButton}
        type="button"
        disabled={audioState === 'RECORDING'}
      >
        <span>{ audioState === 'PLAYING'  ? 'STOP' : 'PLAY' }</span>
      </Button>
    </div>
  );
};

const useStyles = makeStyles({
  recordControlsContainer: {
    display: "grid",
    marginTop: "64px",
    marginBottom: "64px",
    gridTemplateColumns: "3fr 1fr",
    gridGap: "16px",
    padding: "16px",
    margin: "16px",
    backgroundColor: "#5448873b",
    borderRadius: "4px",
    border: "1px solid black",
  },
  recordButton: {
    width: "100%",
    background: "red!important",
    height: "80px",
    color: "white",
  },
  playButton: {
    width: "100",
    background: "green!important",
    height: "80px",
    color: "white",
  },
});

export default VoiceComponent;
