import { CircularProgress, FormHelperText, InputLabel, makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import HCaptchaField from "../../HCaptcha";
import { useCreateCommunityBoardListing } from "../../Hooks/useCommunityBoard";
import { useMediaUpload } from "../../Hooks/useMediaUpload";
import FileUpload from "../Component/FileUpload";
import { Link } from "react-router-dom";

interface Form {
  itemName: string;
  description: string;
  price: number;
  contactName: string;
  contactPhone: string;
  captcha: string;
}

const CommunityBoard = () => {
  const [file, setFile] = useState<File>();
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<Form>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: {
      contactPhone: "905-",
      price: 0,
    },
  });

  const communityBoardMutation = useCreateCommunityBoardListing({
    onSuccess: (response: string) => {
      if (response === "success") {
        navigate("/");
      } else {
      }
    },
  });

  const mediaMutation = useMediaUpload();

  const onSubmit: SubmitHandler<Form> = (data) => {
    if (file) {
      mediaMutation.mutate(
        {
          file,
          filename: file.name,
        },
        {
          onSuccess: (response) => {
            communityBoardMutation.mutate({
              item_name: data.itemName,
              contact_name: data.contactName,
              contact_phone: data.contactPhone,
              description: data.description,
              media_id: response.id,
              price: data.price,
              captcha: data.captcha,
            });
          },
        }
      );
    }
  };

  const handleFileChange = (file: File) => {
    setFile(file);
  };

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Helmet>
        <style type="text/css">{"body { background-color: #1b1537; }"}</style>
      </Helmet>
      <div className={classes.container}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.donateContainer}>
            <div className={classes.donateHeader}>
              <div className={classes.donateHeaderImage}>
                <div className={classes.donateHeaderImageOverlay}>
                  <Link to={"/"}>
                    <div className={classes.donateHeaderPortrait} />
                  </Link>
                  <div className={classes.donateHeaderTextContainer}>
                    <div className={classes.donateHeaderText}>Free Community Board Listing</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.donateMoneyContainer}>
              <div className={classes.donateMoneyContainerInside}>
                <div className={classes.donateMoneyContainerInputContainer}>
                  {/* TITLE OF ITEM */}
                  <div className={classes.donateMoneyContainerInputNameContainer}>
                    <Controller
                      control={control}
                      name="itemName"
                      rules={{
                        maxLength: 25,
                        required: true,
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          error={errors?.itemName !== undefined}
                          fullWidth
                          helperText={
                            (errors?.itemName?.type === "required" && "Name of item is required!") ||
                            (errors?.itemName?.type === "maxLength" && "Max amount of characters is 25!") ||
                            ""
                          }
                          label="Item Name"
                          variant="outlined"
                        />
                      )}
                    />
                  </div>
                  {/* DESCRIPTION */}
                  <div className={classes.tipMessageContainer}>
                    <Controller
                      control={control}
                      name="description"
                      rules={{
                        required: true,
                        maxLength: 255,
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          error={errors?.description !== undefined}
                          helperText={
                            (errors?.description?.type === "required" && "A description is required!") ||
                            (errors?.description?.type === "maxLength" && "Max amount of characters is 320!") ||
                            ""
                          }
                          fullWidth
                          label="Item Description"
                          multiline
                          rows={4}
                          variant="outlined"
                        />
                      )}
                    />
                  </div>
                  {/* DOLLAR AMOUNT */}
                  <div className={classes.donateMoneyPresetsContainer}>
                    <FormControl error={errors?.price !== undefined} className={classes.dollarAmmountInput}>
                      <InputLabel htmlFor="standard-adornment-amount">Item Listing Price</InputLabel>
                      <Controller
                        control={control}
                        name="price"
                        rules={{
                          required: true,
                          min: 0,
                          max: 9999,
                        }}
                        render={({ field }) => (
                          <Input
                            {...field}
                            type="number"
                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                          />
                        )}
                      />
                      {errors?.price !== undefined && (
                        <p className="MuiFormHelperText-root">A proper price is required! (0-9999)</p>
                      )}
                    </FormControl>
                  </div>
                  {/* NAME */}
                  <div className={classes.donateMoneyContainerInputNameContainer}>
                    <Controller
                      control={control}
                      name="contactName"
                      rules={{
                        maxLength: 25,
                        required: true,
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          error={errors?.contactName !== undefined}
                          fullWidth
                          helperText={
                            (errors?.contactName?.type === "maxLength" && "Max amount of characters is 20!") || ""
                          }
                          label="Contact Name"
                          name="name"
                          variant="outlined"
                        />
                      )}
                    />
                  </div>
                </div>

                {/* PHONE */}
                <div className={classes.donateMoneyContainerInputNameContainer}>
                  <Controller
                    control={control}
                    name="contactPhone"
                    rules={{
                      pattern: /^905-\d{3}-\d{4}$/i,
                      required: true,
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        error={errors?.contactPhone !== undefined}
                        fullWidth
                        helperText={
                          (errors?.contactPhone?.type === "pattern" &&
                            "Your phone number must be in the format of 905-###-####!") ||
                          ""
                        }
                        label="Contact Phone"
                        variant="outlined"
                      />
                    )}
                  />
                </div>
              </div>
              <FileUpload onChange={handleFileChange} />
              <div className={classes.captchaContainer}>
                <Controller
                  name="captcha"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <HCaptchaField name={field.name} value={field.value} onChange={field.onChange} />
                  )}
                />
                {errors.captcha ? <FormHelperText error>HCaptcha is required.</FormHelperText> : null}
              </div>
              <div className={classes.donateButtonContainer}>
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  className={classes.donateButton}
                  variant="contained"
                  color="primary"
                >
                  SUBMIT FOR APPROVAL (FREE)
                  {isSubmitting && <CircularProgress thickness={10.0} color={"primary"} size={30} />}
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  root: {
    fontFamily: "Nunito",
    fontWeight: 400,
    maxWidth: "640px",
    margin: "0 auto",
  },
  container: {
    marginTop: "28px",
    display: "flex",
    maxHeight: "6000px",
    flexDirection: "column",
  },
  donateContainer: {
    display: "flex",
    flexDirection: "column",
    flex: "0 0 auto",
    boxSizing: "inherit",
  },
  donateHeader: {
    maxWidth: "640px",
    zIndex: 200,
    position: "relative",
  },
  donateHeaderImage: {
    maxWidth: "640px",
    position: "relative",
    backgroundImage: "url(https://cdn.streamelements.com/static/user/tipping_default.jpg)",
    height: "200px",
    backgroundPosition: "center center",
  },
  donateHeaderImageOverlay: {
    paddingLeft: "30px",
    paddingRight: "30px",
    marginBottom: "-20px",
    flexDirection: "row",
    alignItems: "center",
    position: "absolute",
    bottom: "0px",
    display: "flex",
    WebkitBoxAlign: "center",
  },
  donateHeaderPortrait: {
    backgroundImage:
      "url(https://yt3.ggpht.com/a/AATXAJySVnNsce8MOTeldpGFihXyPnZbK-IHjUaHGA=s88-c-k-c0xffffffff-no-rj-mo)",
    backgroundSize: "cover",
    height: "110px",
    zIndex: 200,
    width: "110px",
    cursor: "pointer",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    borderRadius: "50%",
    borderWidth: "4px",
    borderStyle: "solid",
    borderColor: "white",
    borderImage: "initial",
    overflow: "hidden",
    flex: "0 0 auto",
    boxSizing: "border-box",
    display: "block",
  },
  donateHeaderTextContainer: {
    marginLeft: "30px",
    alignItems: "flex-start",
    display: "flex",
    WebkitBoxPack: "center",
    justifyContent: "center",
    flexDirection: "column",
    WebkitBoxAlign: "center",
    boxSizing: "border-box",
  },
  donateHeaderText: {
    color: "white",
    textShadow: "rgba(0, 0, 0, 0.5) 0px 2px 4px",
    textAlign: "left",
    fontSize: "20px",
    letterSpacing: "1.5px",
    overflowWrap: "break-word",
    wordBreak: "break-word",
    paddingLeft: "18px",
    paddingRight: "18px",
    boxSizing: "border-box",
    display: "block",
  },
  donateMoneyContainer: {
    paddingTop: "20px",
    backgroundColor: "white",
    width: "100%",
    maxWidth: "640px",
    position: "relative",
    flex: "1 1 0%",
    overflow: "hidden",
    boxSizing: "border-box",
    display: "block",
  },
  donateMoneyContainerInside: {
    opacity: 1,
    backgroundColor: "white",
    paddingLeft: "18px",
    paddingRight: "18px",
    display: "flex",
    flexDirection: "column",
    margin: "0px !important",
    boxSizing: "border-box",
  },
  donateMoneyContainerInputContainer: {
    boxSizing: "border-box",
    display: "block",
  },
  donateMoneyContainerInputNameContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    WebkitBoxPack: "justify",
    boxSizing: "border-box",
    marginTop: "24px",
  },
  donateMoneyPresetsContainer: {
    display: "flex",
    marginTop: "40px",
    marginBottom: "40px",
  },
  donateMoneyPresetContainerInterior: {
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    marginLeft: "18px",
  },
  dollarAmmountInput: {
    width: "100%",
  },
  tipMessageContainer: {
    display: "flex",
    marginTop: "28px",
  },
  captchaContainer: {
    width: "100%",
    margin: "24px",
    display: "flex",
    alignItems: "center",
  },
  donateButtonContainer: {
    marginTop: "32px",
    paddingLeft: "18px",
    paddingRight: "18px",
    paddingBottom: "32px",
  },
  donateButton: {
    width: "100%",
    background: "linear-gradient(284deg, rgb(112, 87, 220), rgb(87, 112, 220))!important",
  },
  error: {
    color: "#FF0000",
    "& > input,select": {
      color: "#FF0000",
      borderColor: "#FF0000",
    },
    "& p": {
      margin: "8px 0px",
      padding: 0,
    },
  },
});

export default CommunityBoard;
