import axios, { AxiosError } from "axios";
import { useMutation, UseMutationOptions } from "react-query";
import configuration from "../../configuration";

export type ShockRequest = {  
  donation_token: string;
  name: string;
};

export type ShockResponse = {
  status: "success" | "used";
};

export async function sendShock(request: ShockRequest) {
  const { data } = await axios.post<ShockResponse>(`${configuration.apiUrl}/donations/shock`, request);
  return data;
}

export function useShock(
  options: Omit<UseMutationOptions<ShockResponse, AxiosError, ShockRequest>, "mutationKey" | "mutationFn">
) {
  return useMutation((request) => sendShock(request), options);
}
