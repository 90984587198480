import React, { createContext } from 'react';
import { Seat } from '../Services/service';

export interface Manifest {
    name: string;
    trade: string;
    country: string;
    item: string;
    bibleVerse: string;
    seat?: Seat;
}

export const manifestContext = createContext<ManifestContext | undefined>(undefined);

export interface ManifestContext {
    manifest?: Manifest,
    setManifest: (manifest: Manifest) => void,
}

export const useManifest = () => {
    const context = React.useContext<ManifestContext | undefined>(manifestContext);
    if (context === undefined) {
        throw new Error('useManifest must be used within Provider.');
    }
    return context;
}

