export const routes = {
  donations: {
    print: "/donate/print",
    voice: "/donate/voicedonations",
    communityform: "donate/communityboard",
    video: "/donate/videodonation",
    shock: "/donate/shockdonation",
    home: "/",
  },
  linktree: "/links",
  doctortree: "/doctortree",
};
