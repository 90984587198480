import axios, { AxiosError } from "axios";
import { useMutation, UseMutationOptions } from "react-query";
import configuration from "../../configuration";

export enum DonationType {
  IMAGE = "image",
  AUDIO = "audio",
  VIDEO = "video",
  SHOCK = "shock",
}

export type DonationRequest = {
  name?: string;
  amount: number;
  message?: string;
  mediaId?: number;
  isMailback?: boolean;
  code?: string;
  type: DonationType;
  promocode?: string;
};

export type DonationResponse = {
  success: boolean;
  redirectUrl: string;
};

export async function createDonation(donation: DonationRequest) {
  const { data } = await axios.post<DonationResponse>(`${configuration.apiUrl}/donations/donate`, donation);
  return data;
}

export async function processDonation(token: string) {
  await axios.post<DonationResponse>(`${configuration.apiUrl}/donations/${token}/process`);
}

export function useCreateDonation(
  options: Omit<UseMutationOptions<DonationResponse, AxiosError, DonationRequest>, "mutationKey" | "mutationFn">
) {
  return useMutation((request) => createDonation(request), options);
}
