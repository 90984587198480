import { makeStyles } from '@material-ui/core';
import qs from 'query-string';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useLocation } from 'react-router-dom';
import { capturePayment } from '../../service';

const Thanks = () => {

    const classes = useStyles();
    const location = useLocation(); 
    const { token } = qs.parse(location.search) as { token: string };


    useEffect(() => {        
        if (token) {
            capturePayment(token);
        }    
    }, [token]);

    return <div className={classes.root}>
        <Helmet>
            <style type="text/css">
                {'body { background-color: #EBD093; }'}
            </style>
        </Helmet>
        <h1>Glorious Day!</h1>
        <p>You’ve reserved your space on the Ark and will be listed on the public manifest.</p>
        <p>Rest easy knowing that YOU will be safe from the flood.</p>
        <p style={{ fontSize: 10 }}>It will take a few minutes for your information to be visible on the Ark manifest.</p>
        <Link to="/ark">
            <button className={classes.confirmButton}>VIEW MANIFEST</button>
        </Link>
    </div>
};

const useStyles = makeStyles({
    root: {
        backgroundColor: '#EBD093',
        padding: '0px 16px',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        paddingBottom: '300px',
        fontFamily: 'Nunito',
        fontWeight: 400,
    },
    confirmButton: {
        backgroundColor: '#20559A',
        border: 0,
        color: '#FFFFFF',
        padding: '16px 24px',
        height: '55px',
        fontSize: '16px',
        fontWeight: 700,
        width: '100%',
        marginTop: '24px',
        cursor: 'pointer',
    },
});

export default Thanks;