import axios, { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import configuration from '../configuration';

export interface CapturePaymentRequest {
    token: string;
}

export interface CapturePaymentResponse {
    status: 'success' | 'fail' | 'already_captured';
}

async function capturePayment(request: CapturePaymentRequest) {
    const { data } = await axios.post<CapturePaymentResponse>(`${configuration.apiUrl}/payments/capture`, request)
    return data;
}

export function useCapturePayment(
    options?: Omit<UseMutationOptions<CapturePaymentResponse, AxiosError, CapturePaymentRequest>, 'mutationKey' | 'mutationFn'>
) {
    return useMutation<CapturePaymentResponse, AxiosError, CapturePaymentRequest>((request) => capturePayment(request), options);
}