import { Button, CircularProgress, makeStyles } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import qs from "qs";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Controller, useForm } from "react-hook-form";
import { Link, useLocation } from "react-router-dom";
import configuration from "../../../configuration";
import { useCapturePayment } from "../../../Hooks/useCapturePayment";
import { useIsOnline } from "../../../Hooks/useIsOnline";
import { routes } from "../../../routes";
import { useShock } from "../../hooks/useShock";
import CopyToClipboardButton from "./CopyToClipboardButton";

interface DonationForm {
  name: string;
  token: string;
  donation_token: string;
}

const ShowControllerView = () => {
  const location = useLocation();
  const classes = useStyles();
  const { token, donation_token } = qs.parse(location.search.slice(1)) as { token: string; donation_token: string };
  const [validPayment, setValidPayment] = useState<boolean>(false);
  const [shockUsed, setShockUsed] = useState<boolean>(false);

  const shockMutation = useShock({
    onSuccess: (response) => {
      setShockUsed(true);
    },
  });

  const [isOnline, setIsOnline] = useState(false);

  useIsOnline({
    onSuccess: (response) => setIsOnline(response),
    refetchInterval: 10000,
  });

  const capturePaymentMutation = useCapturePayment({
    onSuccess: (response) => {
      if (response.status === "success" || response.status === "already_captured") {
        setValidPayment(true);
      }
    },
  });

  useEffect(() => {
    capturePaymentMutation.mutate({
      token,
    });
  }, [token]);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<DonationForm>({
    defaultValues: {
      donation_token: donation_token,
      token: token,
    },
    mode: "onChange",
  });

  const onSubmit = (data: DonationForm) => {
    shockMutation.mutate({
      donation_token: data.donation_token,
      name: data.name,
    });
  };

  return (
    <div className={classes.root}>
      <Helmet>
        <style type="text/css">{"body { background-color: #1b1537; }"}</style>
      </Helmet>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.container}>
          <div className={classes.donateContainer}>
            <div className={classes.donateHeader}>
              <div className={classes.donateHeaderImage}>
                <div className={classes.donateHeaderImageOverlay}>
                  <Link to={"/"}>
                    <div className={classes.donateHeaderPortrait} />
                  </Link>
                  <div className={classes.donateHeaderTextContainer}>
                    <div className={classes.donateHeaderText}>Hack Controller</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {validPayment ? (
            <div className={classes.donateMoneyContainer}>
              <div className={classes.donateMoneyContainerInside}>
                <div className={classes.donateMoneyContainerInputContainer}>
                  <div className={classes.donateMoneyContainerInputNameContainer}>
                    <iframe
                      className={classes.iframeContainer}
                      width="100%"
                      src={configuration.twitchUrl}
                      title="Twitch video player"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    ></iframe>
                    {!shockUsed ? (
                      <div style={{ width: "100%" }}>
                        <Controller
                          control={control}
                          name="name"
                          rules={{
                            maxLength: 20,
                          }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              error={errors?.name !== undefined}
                              fullWidth
                              helperText={
                                (errors?.name?.type === "maxLength" && "Max amount of characters is 20!") ||
                                "Leave blank to be Anonymous"
                              }
                              label="Your Name"
                              variant="outlined"
                            />
                          )}
                        />
                        <div className={classes.warningContainer}>
                          <h4 className={classes.warningText}>
                            {isOnline
                              ? "This button can only be pressed once"
                              : `Hacks are termporarily turned off. They will be turned on in a few moments. You can also bookmark this unique shock URL and come back later.`}
                          </h4>
                          {isOnline ? (
                            <Button
                              fullWidth
                              type="submit"
                              size="large"
                              variant="contained"
                              disabled={shockUsed || shockMutation.isLoading}
                              style={{
                                backgroundColor: "red",
                                color: "yellow",
                                marginTop: "24px",
                                marginBottom: "24px",
                              }}
                            >
                              ⚡️⚠️ Send Hack ⚠️⚡️
                            </Button>
                          ) : (
                            <Button fullWidth size="large" variant="contained" disabled={true}>
                              ⚡️⚠️ Send Hack ⚠️⚡️
                            </Button>
                          )}
                        </div>
                        <CopyToClipboardButton value={window.location.href} />
                      </div>
                    ) : (
                      <div>
                        <p>Short circuit sent!</p>
                        <Button
                          type="submit"
                          className={classes.donateButton}
                          variant="contained"
                          color="primary"
                          href={routes.donations.home}
                        >
                          Donate Again
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
                <div className={classes.donateMoneyPresetsContainer}>
                  <div className={classes.donateMoneyPresetContainerInterior}></div>
                </div>
                <div className={classes.tipMessageContainer}></div>
              </div>
            </div>
          ) : (
            <CircularProgress />
          )}
        </div>
      </form>
    </div>
  );
};

const useStyles = makeStyles({
  root: {
    fontFamily: "Nunito",
    fontWeight: 400,
    maxWidth: "640px",
    margin: "0 auto",
    marginBottom: "24px",
  },
  iframeContainer: {
    marginTop: 24,
    marginBottom: 16,
    aspectRatio: "16/9",
  },
  container: {
    marginTop: "28px",
    display: "flex",
    maxHeight: "6000px",
    flexDirection: "column",
  },
  warningContainer: {
    border: "1px solid red",
    flexDirection: "column",
    borderRadius: "10px",
    padding: "16px",
    backgroundColor: "#ffeaea",
    marginTop: "16px",
    marginBottom: "16px",
  },
  warningText: {
    color: "red",
    textAlign: "center",
    margin: 0,
  },
  donateContainer: {
    display: "flex",
    flexDirection: "column",
    flex: "0 0 auto",
    boxSizing: "inherit",
  },
  donateHeader: {
    maxWidth: "640px",
    zIndex: 200,
    position: "relative",
  },
  donateHeaderImage: {
    maxWidth: "640px",
    position: "relative",
    backgroundImage: "url(https://cdn.streamelements.com/static/user/tipping_default.jpg)",
    height: "200px",
    backgroundPosition: "center center",
  },
  donateHeaderImageOverlay: {
    paddingLeft: "30px",
    paddingRight: "30px",
    marginBottom: "-20px",
    flexDirection: "row",
    alignItems: "center",
    position: "absolute",
    bottom: "0px",
    display: "flex",
    WebkitBoxAlign: "center",
  },
  donateHeaderPortrait: {
    backgroundImage:
      "url(https://yt3.ggpht.com/a/AATXAJySVnNsce8MOTeldpGFihXyPnZbK-IHjUaHGA=s88-c-k-c0xffffffff-no-rj-mo)",
    backgroundSize: "cover",
    height: "110px",
    zIndex: 200,
    width: "110px",
    cursor: "pointer",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    borderRadius: "50%",
    borderWidth: "4px",
    borderStyle: "solid",
    borderColor: "white",
    borderImage: "initial",
    overflow: "hidden",
    flex: "0 0 auto",
    boxSizing: "border-box",
    display: "block",
  },
  donateHeaderTextContainer: {
    marginLeft: "30px",
    alignItems: "flex-start",
    display: "flex",
    WebkitBoxPack: "center",
    justifyContent: "center",
    flexDirection: "column",
    WebkitBoxAlign: "center",
    boxSizing: "border-box",
  },
  donateHeaderText: {
    color: "white",
    textShadow: "rgba(0, 0, 0, 0.5) 0px 2px 4px",
    textAlign: "left",
    fontSize: "20px",
    letterSpacing: "1.5px",
    overflowWrap: "break-word",
    wordBreak: "break-word",
    paddingLeft: "18px",
    paddingRight: "18px",
    boxSizing: "border-box",
    display: "block",
  },
  donateMoneyContainer: {
    paddingTop: "20px",
    backgroundColor: "white",
    width: "100%",
    maxWidth: "640px",
    position: "relative",
    flex: "1 1 0%",
    overflow: "hidden",
    boxSizing: "border-box",
    display: "block",
  },
  donateMoneyContainerInside: {
    opacity: 1,
    backgroundColor: "white",
    height: "100%",
    paddingLeft: "18px",
    paddingRight: "18px",
    display: "flex",
    flexDirection: "column",
    margin: "0px !important",
    boxSizing: "border-box",
  },
  donateMoneyContainerInputContainer: {
    boxSizing: "border-box",
    display: "block",
  },
  donateMoneyContainerInputNameContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    WebkitBoxPack: "justify",
    boxSizing: "border-box",
    width: "100%",
  },
  donateMoneyPresetsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    WebkitBoxPack: "justify",
    justifyContent: "space-between",
    boxSizing: "border-box",
    marginTop: "28px",
    width: "100%",
  },
  donateMoneyPresetContainerInterior: {
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    marginLeft: "18px",
  },
  dollarAmmountInput: {
    paddingRight: "18px",
    width: "120px",
  },
  tipMessageContainer: {
    display: "flex",
    paddingLeft: "18px",
    paddingRight: "18px",
    marginTop: "28px",
  },
  fileUploadContainer: {
    border: "1px solid black",
    height: "300px",
    marginLeft: "18px",
    marginRight: "18px",
    marginTop: "28px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  fileUploadLabel: {
    marginLeft: "18px",
    lineHeight: "24px",
  },
  donateButtonContainer: {
    marginTop: "32px",
    paddingLeft: "18px",
    paddingRight: "18px",
    paddingBottom: "32px",
  },
  donateButton: {
    width: "100%",
    background: "linear-gradient(284deg, rgb(112, 87, 220), rgb(87, 112, 220))!important",
  },
  leaderboardContainer: {
    width: "100%",
    margin: "0 auto",
    marginTop: "32px",
  },
  img: {
    height: "100%",
    width: "100%",
    objectFit: "cover",
  },
});

export default ShowControllerView;
